import styled from "styled-components";
import boatImage from "../../assets/images/tradeImage.jpg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogoLangCtr = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const LanguageCtr = styled.div`
  display: flex;
  gap: 0.5rem;
  width: 25rem;
  justify-content: flex-end;
  align-content: center;
  margin-right: 2rem;
  width: 100%;
`;

export const LangBtn = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  z-index: 10;

  > button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-style: bold;
    color: ${({ theme: { colors } }) => colors.White};

    &:hover {
      background-color: ${({ theme: { colors } }) => colors.Green};
      color: ${({ theme: { colors } }) => colors.White};
    }

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`;

export const LangFlag = styled.img`
  opacity: ${({ activeLang }) => (activeLang ? 0.5 : 1)};
  height: 1rem;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${({ theme: { colors } }) => colors.White};
  padding: 0 5px;
  height: 6rem;

  > span {
    text-align: center;
    font-size: 2rem;
  }

  @media screen and (max-width: 820px) {
    > span {
      font-size: 1.1rem;
    }
  }
`;

export const LogosCtr = styled.div`
  display: flex;
  margin: 0.2rem 2rem;
  gap: 0.2rem;
  align-items: center;
  justify-content: start;

  @media screen and (max-width: 820px) {
    margin: 0.2rem;
    width: 100%;
  }
`;

export const ECALogo = styled.a`
  > img {
    width: 90%;

    @media screen and (max-width: 820px) {
      width: 70%;
    }

    @media screen and (max-width: 768px) {
      width: 70%;
    }
  }
`;

export const ATPCLogo = styled.a`
  > img {
    width: 8rem;
    height: 4rem;
  }

  @media screen and (max-width: 820px) {
    margin-bottom: 8px;
    margin-left: 5px;
    width: 4rem;
    height: 2rem;
  }
`;

export const Jumbotron = styled.div`
  background: url(${boatImage}) no-repeat fixed bottom;
  background-size: cover;
  color: ${({ theme: { colors } }) => colors.White};
  height: 250px;
  position: relative;
  opacity: 0.8;
  /* z-index: -2; */
  @media screen and (max-width: 834px) {
    height: 225px;
  }
`;

export const Overlay = styled.div`
  background-color: #000;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
`;
