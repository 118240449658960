import styled from 'styled-components';
import uneca from '../../assets/images/uneca.png';

const Divider = () => {
  return (
    <AstroDivider>
      <AstroDividerMask />
      <span>
        <i>
          <img src={uneca} alt="Divider eca logo" />
        </i>
      </span>
    </AstroDivider>
  );
};

const AstroDivider = styled.div`
  /* margin: 10px auto; */
  width: 400px;
  max-width: 100%;
  position: relative;
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  > span {
    width: 35px;
    height: 35px;
    position: absolute;
    bottom: 100%;
    margin-bottom: -19px;
    left: 50%;
    margin-left: -25px;
    border-radius: 100%;
    /* box-shadow: 0 2px 4px ${({ theme: { colors } }) => colors.Teal};  */
    box-shadow: 0 2px 4px #024575;
    background: ${({ theme: { colors } }) => colors.White};

    > i {
      position: absolute;
      top: 2px;
      bottom: 2px;
      left: 2px;
      right: 2px;
      border-radius: 100%;
      /* border: 1px dashed ${({ theme: { colors } }) => colors.Teal}; */
      border: 1px dashed #024575;
      text-align: center;
      line-height: 30px;
      font-style: normal;
      /* color: ${({ theme: { colors } }) => colors.DarkTeal}; */
      color: #012b49;

      > img {
        width: 100%;
      }
    }
  }
`;

const AstroDividerMask = styled.div`
  overflow: hidden;
  height: 15px;

  &:after {
    content: '';
    display: block;
    margin: -25px auto 0;
    width: 100%;
    height: 25px;
    border-radius: 125px / 12px;
    /* box-shadow: 0 0 8px #049372; */
    box-shadow: 0 0 8px #024575;
  }
`;

export default Divider;
