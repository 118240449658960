import React from 'react';
import styled from 'styled-components';
import Divider from './Divider';

const PageTitle = ({ title, children }) => {
  return (
    <Container>
      <h2>{title}</h2>
      <Divider />
      {children}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  > h2 {
    margin: 0.3rem 0;
    width: 100%;
    display: flex;
    font-size: 1.5rem;
    padding: 0.7rem;
    justify-content: center;
    align-items: center;
    color: ${({ theme: { colors } }) => colors.Grey};
  }
`;

export default PageTitle;
