import { DtriContext } from "../context/dtri";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import euiLogo from "../assets/images/eui-logo.png";
import OecdLogo from "../assets/images/OecdLogo";
import EscapLogo from "../assets/images/EscapLogo";
import EclacLogo from "../assets/images/EclacLogo";
import AfdbLogo from "../assets/images/AfdbLogo";
import ecalogo from "../assets/images/ecalogo.png";
import rialogo from "../assets/images/escap-ria.png";
import auclogo from "../assets/images/auweblogo-en.png";
import digiSRII from "../assets/images/DigiSRII_logo.png";
// import digiSRII from "../assets/images/DigiSRII-logo-small.png";

const AfdbEca = () => (
  <div className="d-flex flex-column">
    <AfdbLogo width={150} height={100} />
    <img
      src={ecalogo}
      alt="ECA logo in partners and initiatives page"
      width={150}
      height={50}
      className="mb-2"
    />
    <img src={auclogo} alt="African Union Logo" width={150} height={50} />
  </div>
);

export const usePartners = () => {
  const { region } = useContext(DtriContext);
  const { t } = useTranslation(["common", "eca", "escap", "eclac"]);

  const pline1 = [
    {
      name: "oecd",
      component: true,
      logo: <OecdLogo />,
      link: "https://www.oecd.org/",
      header: `${t("Links.OECD", { ns: region })}`,
    },
    {
      name: "eui",
      component: false,
      logo: euiLogo,
      link: "https://globalgovernanceprogramme.eui.eu/digital-trade-integration/",
      header: `${t("Links.EUI", { ns: region })}`,
    },
  ];
  const pline2 = [
    {
      name: "escap",
      component: true,
      logo: <EscapLogo />,
      link: "https://riva.negotiatetrade.org/",
      header: `${t("Links.ESCAP", { ns: region })}`,
    }/*,
    {
      name: "escap_srii",
      component: false,
      logo: digiSRII,
      link: "https://www.unescap.org/projects/digisrii",
      header: `${t("Links.DIGISRII", { ns: region })}`,
    }*/,
    {
      name: "escap_ria",
      component: false,
      logo: rialogo,
      link: "https://riva.negotiatetrade.org/#/rioverview",
      header: `${t("Links.RIA", { ns: region })}`,
    },
  ];
  const pline3 = [
    {
      name: "eclac",
      component: true,
      logo: <EclacLogo />,
      link: "https://www.cepal.org/en/publications/47370-digital-and-sustainable-trade-facilitation-latin-america-and-caribbean-regional",
      header: `${t("Links.ECLAC", { ns: region })}`,
    },
    {
      name: "arii",
      component: true,
      logo: <AfdbEca />,
      link: "https://arii.uneca.org/",
      header: `${t("Links.ARII", { ns: region })}`,
    },
  ];

  return { pline1, pline2, pline3 };
};
