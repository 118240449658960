import { createContext, useState, useEffect } from "react";
import { createAPIEndpoint, ENDPOINTS } from "../api";

const DtriContext = createContext();

const DtriProvider = ({ children }) => {
  const [countries, setCountries] = useState([]);
  const [publications, setPublications] = useState([]);
  const [region, setRegion] = useState("common");

  useEffect(() => {
    async function fetchCountries() {
      try {
        const { data } = await createAPIEndpoint(ENDPOINTS.COUNTRY).fetch();
        setCountries(data);
      } catch (error) {
        console.log("Unsuccessful API fetch call for countries");
      }
    }
    fetchCountries();
  }, []);

  useEffect(() => {
    async function fetchPubs() {
      try {
        const { data } = await createAPIEndpoint(ENDPOINTS.PUBLICATION).fetch();
        setPublications(data);
      } catch (error) {
        console.log("Unsuccessful API fetch call for publications");
      }
    }
    fetchPubs();
  }, []);

  const handleRegion = (reg) => {
    setRegion(reg);
  };

  return (
    <DtriContext.Provider
      value={{
        countries,
        publications,
        handleRegion,
        region,
      }}
    >
      {children}
    </DtriContext.Provider>
  );
};

export { DtriProvider, DtriContext };
