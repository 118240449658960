import i18n from "i18next";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { useContext } from "react";
import { DtriContext } from "../../context/dtri";

import {
  Container,
  Title,
  LanguageCtr,
  LangBtn,
  LangFlag,
  LogosCtr,
  ECALogo,
  ATPCLogo,
  Overlay,
  Jumbotron,
  LogoLangCtr,
} from "./HeaderStyles";
import gbflag from "../../assets/flags/1x1/gb.svg";
import frflag from "../../assets/flags/1x1/fr.svg";
import esflag from "../../assets/flags/1x1/es.svg";
import ecaLogo from "../../assets/images/ecalogo.png";
import escapLogo from "../../assets/images/escap-logo-white.png";
import eclacLogo from "../../assets/images/eclac-logo-small.png";
import eclacLogoSP from "../../assets/images/eclac-logo-es-small.png";
import atpcfr from "../../assets/images/atpc-fr.jpg";
import atpc from "../../assets/images/atpc.jpg";
import cealogo from "../../assets/images/cealogo.png";
import NavbarBs from "../NavbarBs";
import LandingHeader from "../LandingHeader";

let languages = [
  {
    code: "en",
    name: "English",
    country_flag: gbflag,
  },
  {
    code: "fr",
    name: "Français",
    country_flag: frflag,
  },
  {
    code: "es",
    name: "Spanish",
    country_flag: esflag,
  },
];

const Header = () => {
  const { t } = useTranslation(["common", "eca", "escap", "eclac"]);
  const currentLangCode = cookies.get("i18next") || "en";
  const { region } = useContext(DtriContext);

  // set the languages based on the selected region
  switch (region) {
    case "eca":
      languages = [
        {
          code: "en",
          name: "English",
          country_flag: gbflag,
        },
        {
          code: "fr",
          name: "Français",
          country_flag: frflag,
        },
        {
          code: "es",
          name: "Español",
          country_flag: esflag,
        },
      ];
      break;
    case "escap":
      languages = [
        {
          code: "en",
          name: "English",
          country_flag: gbflag,
        },
        {
          code: "fr",
          name: "Français",
          country_flag: frflag,
        },
        {
          code: "es",
          name: "Español",
          country_flag: esflag,
        },
      ];
      break;
    case "eclac":
      languages = [
        {
          code: "en",
          name: "English",
          country_flag: gbflag,
        },
        {
          code: "fr",
          name: "Français",
          country_flag: frflag,
        },
        {
          code: "es",
          name: "Español",
          country_flag: esflag,
        },
      ];
      break;
    default:
      break;
  }

  const regionLogo =
    currentLangCode === "en"
      ? region === "eca"
        ? ecaLogo
        : region === "escap"
        ? escapLogo
        : eclacLogo
      : currentLangCode === "fr"
      ? region === "eca"
        ? cealogo
        : region === "escap"
        ? escapLogo
        : eclacLogo
      : currentLangCode === "es"
      ? region === "eca"
        ? ecaLogo
        : region === "escap"
        ? escapLogo
        : eclacLogoSP
      : "";
  const regionURL =
    currentLangCode === "en"
      ? region === "eca"
        ? "https://www.uneca.org/"
        : region === "escap"
        ? "https://www.unescap.org/"
        : "https://www.cepal.org/en"
      : currentLangCode === "fr"
      ? region === "eca"
        ? "https://www.uneca.org/fr"
        : region === "escap"
        ? "https://www.unescap.org/"
        : "https://www.cepal.org/en"
      : currentLangCode === "es"
      ? region === "eca"
        ? "https://www.uneca.org/"
        : region === "escap"
        ? "https://www.unescap.org/"
        : "https://www.cepal.org/es"
      : "";

  if (region !== "common") {
    return (
      <Container>
        <Jumbotron>
          <Overlay />
          <LogoLangCtr>
            <LogosCtr>
              <ECALogo href={regionURL}>
                <img src={regionLogo} alt="Logo" width="40%" />
              </ECALogo>
              {region === "eca" && (
                <ATPCLogo href="https://uneca.org/african-trade-policy-centre">
                  <img
                    src={currentLangCode === "en" ? atpc : atpcfr}
                    alt="ATPC Logo"
                  />
                </ATPCLogo>
              )}
            </LogosCtr>
            <LanguageCtr>
              {languages.map(({ code, name, country_flag }) => (
                <LangBtn key={code}>
                  <LangFlag
                    activeLang={code === currentLangCode}
                    src={country_flag}
                    alt={`${name} language flag`}
                  />
                  <button
                    onClick={() => i18n.changeLanguage(code)}
                    disabled={code === currentLangCode}
                  >
                    {name}
                  </button>
                </LangBtn>
              ))}
            </LanguageCtr>
          </LogoLangCtr>
          <Title>
            <span>{t("homepage_title", { ns: region })}</span>
          </Title>
        </Jumbotron>
        <NavbarBs />
      </Container>
    );
  }
  return <LandingHeader />;
};

export default Header;
