import { useState } from 'react';
import styled from 'styled-components';

const YearSlider = ({ maxYear, minYear, onYearChange }) => {
  const [sliderValue, setSliderValue] = useState(maxYear);

  const handleChange = (e) => {
    setSliderValue(e.target.value);
    onYearChange(e.target.value);
  };

  return (
    <SliderBox>
      <Slider>
        <input
          type="range"
          min={minYear}
          max={maxYear}
          value={sliderValue}
          onChange={(e) => handleChange(e)}
        />
      </Slider>
      <Value>{sliderValue}</Value>
    </SliderBox>
  );
};

const SliderBox = styled.div`
  margin-top: 10px;
  background: white;
  padding: 5px 5px 5px 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Value = styled.div`
  font-size: 25px;
  font-weight: 600;
  font-family: sans-serif;
  color: #094c79;
  width: 55px;
  text-align: center;
  padding-right: 5px;
`;

const Slider = styled.div`
  height: 40px;
  width: 30rem;
  display: flex;
  align-items: center;
  margin-right: 15px;

  > input {
    height: 20%;
    width: 100%;
    outline: none;
    border-radius: 25px;
    background: #5e5c5c;
    -webkit-appearance: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 27px;
      width: 27px;
      cursor: pointer;
      border-radius: 50%;
      border: 5px double #fff;
      background: grey;
    }

    &::-moz-range-progress {
      height: 10px;
      border-radius: 25px;
      background: #3498db;
    }
  }
`;

export default YearSlider;
