import styled from 'styled-components';

export const LoginCtr = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-left: ${({ closed }) => (closed ? '110px' : '330px')};

  > form {
    width: 400px;
    background-color: var(--primary-color-light);
    padding: 10px 20px;
    border-radius: 10px;

    > h2 {
      text-align: center;
      color: var(--text-color);
    }

    > button {
      width: 100%;
      height: 50px;
      padding: 10px;
      border: none;
      border-radius: 10px;
      color: white;
      font-size: 18px;
      font-weight: bold;
      background-color: var(--primary-color);
      cursor: pointer;
      margin-top: 15px;
      margin-bottom: 30px;
    }
  }
`;

export const FormInputCtr = styled.div`
  display: flex;
  flex-direction: column;

  > label {
    color: var(--text-color);
  }

  > input {
    padding: 10px;
    margin: 10px 0px;
    border: 1px solid gray;
    border-radius: 5px;

    :invalid[focused='true'] ~ span {
      display: block;
    }
    :invalid[focused='true'] {
      border: 1px solid var(--red);
    }
  }

  > span {
    font-size: 12px;
    padding: 5px;
    color: var(--red);
    display: none;
  }
`;

export const ChangePwdMsg = styled.span`
  color: ${({ changed }) => (changed ? 'var(--blue)' : 'var(--red)')};
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;
