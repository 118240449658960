import Highmaps, { MapView } from "highcharts/highmaps";
import { useTranslation } from "react-i18next";
import {
  HighmapsProvider,
  HighchartsMapChart,
  MapSeries,
  MapNavigation,
  Tooltip,
  Legend,
  Subtitle,
  Title,
} from "react-jsx-highmaps";

import { useMap } from "../../hooks/useMap";
import useWindowDim from "../../hooks/useWindowDim";
import { useContext } from "react";
import { DtriContext } from "../../context/dtri";

const RegionMap = ({ countryScores, setCountryCode, tradeType }) => {
  const { tarWidth, tarHeight } = useWindowDim();
  const map = useMap();
  const { t } = useTranslation(["common", "eca", "escap", "eclac"]);
  const { region } = useContext(DtriContext);

  const handleClick = (e) => {
    setCountryCode(`${e.point.properties["iso-a3"].split(",")[0]}`);
  };

  const containerProps = {
    style: {
      width: tarWidth || "50vw",
      height: tarHeight || "100vh",
    },
  };

  const colorAxis = {
    minColor: "#FFFFFF",
    maxColor: "#006666",
  };

  return (
    <HighmapsProvider Highcharts={Highmaps}>
      <HighchartsMapChart
        map={map}
        containerProps={containerProps}
        colorAxis={colorAxis}
        exporting={{
          enabled: true,
          buttons: {
            menuItems: [
              "viewFullscreen",
              "printChart",
              "separator",
              "downloadPNG",
              "downloadJPEG",
              "downloadPDF",
              "downloadSVG",
            ],
          },
        }}
      >
        <Title>{t("TR.map_title", { ns: region })}</Title>
        <Subtitle>
          {tradeType === "TI"
            ? t("TIS.scale_note", { ns: region })
            : t("TR.scale_note", { ns: region })}
        </Subtitle>
        <MapSeries
          name={region === "escap" ? "Economy" : "Country"}
          states={{
            hover: { color: "#a4edba" },
            select: {
              color: "#445248",
              borderColor: "black",
              dashStyle: "shortDot",
            },
          }}
          data={countryScores}
          dataLabels={{
            enabled: true,
            color: "#FFFFFF",
            style: { fontSize: "10px" },
            format: "{point.name}",
            // formatter: function () {
            //   return this.point.properties['country-abbrev'].split(',')[0];
            // },
          }}
          onClick={handleClick}
          cursor="pointer"
          allowPointSelect="true"
          allAreas="false"
        />
        <MapNavigation
          enabled={true}
          buttonOptions={{ verticalAlign: "bottom" }}
        >
          <MapNavigation.ZoomIn verticalAlign="bottom" />
          <MapNavigation.ZoomOut verticalAlign="bottom" />
        </MapNavigation>
        <Tooltip
          padding={10}
          hideDelay={250}
          shape="square"
          split={false}
          followPointer={false}
          pointFormat="{point.name}: {point.value}"
          headerFormat="<b>{series.name}</b><br>"
        />
        <Legend />
      </HighchartsMapChart>
    </HighmapsProvider>
  );
};

export default RegionMap;
