import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../components/common";
import { Container, Row, Col } from "react-bootstrap";
import { DtriContext } from "../../context/dtri";
import PubCard from "./PubCard";

const Publication = () => {
  const { publications, region } = useContext(DtriContext);
  const { t, i18n } = useTranslation(["common", "eca", "escap", "eclac"]);
  console.log(region);
  console.log(publications);

  let colsPerRow = 4;
  let pubs = [];
  let temp = [];
  let sortedPublications = [];

  // filter the returned publications by region
  const filtered_publications = publications.filter(
    (publication) => publication.region === region
  );

  if (i18n.language === "en")
    sortedPublications = filtered_publications.sort((a, b) =>
      a.pubNameEn < b.pubNameEn ? -1 : a.pubNameEn > b.pubNameEn ? 1 : 0
    );
  else if (i18n.language === "fr")
    sortedPublications = filtered_publications.sort((a, b) =>
      a.pubNameFr < b.pubNameFr ? -1 : a.pubNameFr > b.pubNameFr ? 1 : 0
    );
  else
    sortedPublications = filtered_publications.sort((a, b) =>
      a.pubNameSp < b.pubNameSp ? -1 : a.pubNameSp > b.pubNameSp ? 1 : 0
    );

  sortedPublications.forEach((item, idx) => {
    if (idx !== 0 && idx % colsPerRow === 0) {
      pubs.push(temp);
      temp = [
        {
          id: item.id,
          pubNameEn: item.pubNameEn,
          pubNameFr: item.pubNameFr,
          pubNameSp: item.pubNameSp,
          pubDescriptionEn: item.pubDescriptionEn,
          pubDescriptionFr: item.pubDescriptionFr,
          pubDescriptionSp: item.pubDescriptionSp,
          pubFileNameEn: item.pubFileNameEn,
          pubFileNameFr: item.pubFileNameFr,
          pubFileNameSp: item.pubFileNameSp,
          region: item.region,
        },
      ];
    } else {
      temp.push({
        id: item.id,
        pubNameEn: item.pubNameEn,
        pubNameFr: item.pubNameFr,
        pubNameSp: item.pubNameSp,
        pubDescriptionEn: item.pubDescriptionEn,
        pubDescriptionFr: item.pubDescriptionFr,
        pubDescriptionSp: item.pubDescriptionSp,
        pubFileNameEn: item.pubFileNameEn,
        pubFileNameFr: item.pubFileNameFr,
        pubFileNameSp: item.pubFileNameSp,
        region: item.region,
      });
    }
  });
  if (temp.length) pubs.push(temp);

  return (
    <Container>
      <PageTitle title={t("PUB.title", { ns: region })}>
        <p>{t("PUB.intro", { ns: region })}</p>
        {pubs.map((pub, idx) => {
          colsPerRow = pub.length < colsPerRow ? pub.length : colsPerRow;
          return (
            <Row
              xs={1}
              md={colsPerRow}
              className="g-4 m-2"
              key={`${idx}-${pub.id}`}
            >
              {pub.map((obj, index) => {
                return (
                  <Col key={obj.id}>
                    <PubCard colorIdx={index % 4} {...obj} />
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </PageTitle>
    </Container>
  );
};

export default Publication;
