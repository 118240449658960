import { useEffect, useContext, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { TiSocialVimeoCircular } from "react-icons/ti";

import Pillar from "../../components/Pillar";
import Scores from "../../components/Scores";
import { Img } from "./TradeRestrictionStyles";
import { DtriContext } from "../../context/dtri";
import { reducer, initialState } from "./reducer";
import RegionMap from "../../components/RegionMap";
import {
  PageTitle,
  YearSlider,
  // DownloadBtn,
  Callout,
} from "../../components/common";
import { ENDPOINTS, createAPIEndpoint } from "../../api";

import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const TradeRestriction = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [maxYear, setMaxYear] = useState(0);
  const [minYear, setMinYear] = useState(0);
  const { countries } = useContext(DtriContext);

  const {
    pillars,
    activeCountries,
    countryCode,
    forYear,
    selectedCountry,
    countryInfo,
    pill,
  } = state;

  const { t } = useTranslation(["common", "eca", "escap", "eclac"]);
  const { region } = useContext(DtriContext);
  const names = t("CountriesA3", { returnObjects: true, ns: region });
  const langPillars = t("TR.Pillars", { returnObjects: true, ns: region });
  // group array by isoa3 and return score corresponds to max year
  const groupMax = (ary) => {
    ary.sort((a, b) => (a.isoa3 > b.isoa3 ? 1 : b.isoa3 > a.isoa3 ? -1 : 0));
    // get unique isoa3 from ary
    const unique = [...new Set(ary.map((item) => item.isoa3))];
    let res = [];
    unique.forEach((x) => {
      const aa = ary.filter((a) => a.isoa3 === x && a.code === "DG.0");
      const maxYear = Math.max(...aa.map((v) => v.year));
      const index = aa.findIndex((object) => {
        return object.year === maxYear;
      });
      res.push({
        isoa3: x,
        score: aa[index].score || 0,
      });
    });
    console.log("Map Data", res);

    return res;
  };

  // used for the year slider
  const setMinMaxYears = (years) => {
    setMaxYear(Math.max(...years.map((y) => y.year)));
    setMinYear(Math.min(...years.map((y) => y.year)));
  };

  useEffect(() => {
    async function fetchTR() {
      try {
        const { data } = await createAPIEndpoint(ENDPOINTS.RESTRICTION).fetch();
        const mapped = data.map((c) => ({
          year: c.year,
          code: c.pillarCode,
          isoa3: c.isoa3,
          score: c.score,
        }));
        dispatch({ type: "PILLARS", payload: mapped });
        setMinMaxYears(mapped);
        dispatch({
          type: "ACTIVE_COUNTRIES",
          payload: groupMax(mapped).map((c) => [c.isoa3, c.score]),
        });
      } catch (error) {
        console.log("Problem with reading Digital Restrictions data");
      }
    }
    fetchTR();
  }, []);

  const handleCountryChange = (cntry) => {
    const pills = pillars
      .filter((p) => p.isoa3 === cntry)
      .sort((a, b) => (a.code < b.code ? -1 : a.code > b.code ? 1 : 0));

    const maxY = Math.max(...pills.map((y) => y.year));
    dispatch({ type: "COUNTRY_CODE", payload: cntry });
    dispatch({ type: "FOR_YEAR", payload: maxY });
    dispatch({
      type: "SELECTED_COUNTRY",
      payload: pills,
    });

    // set min and max years for slider
    setMinMaxYears(pills);

    // get data to display overall score and validated flag per country and selected year
    const country = countries.find((c) => c.isoa3 === cntry) || null;
    if (country) {
      const yr = forYear === new Date().getFullYear() ? maxYear : forYear;
      const score = pillars.find(
        (s) => s.isoa3 === cntry && s.year === parseInt(yr) && s.code === "DG.0"
      );
      if (score)
        dispatch({
          type: "COUNTRY_INFO",
          payload: {
            overAll: score.score,
            validated: country.restrictionValidated,
          },
        });
    }
  };

  const handleForYear = (yr) => dispatch({ type: "FOR_YEAR", payload: yr });

  const popover = (
    <Popover id="popover-basic" style={{ maxWidth: 400 }}>
      <Popover.Header as="h3">Map Disclaimer Text</Popover.Header>
      <Popover.Body>
        <strong>LIST OF MAP DISCLAIMERS (E, F, S, A, C, R)</strong>
        <br />
        Short Disclaimer:
        <br />
        (E) The boundaries and names shown and the designations used on this map
        do not imply official endorsement or acceptance by the United Nations.
        <br />
        Final boundary between the Republic of Sudan and the Republic of South
        Sudan has not yet been determined.
        <br />
        (F) Les frontières et les noms indiqués et les désignations employées
        sur cette carte n'impliquent pas reconnaissance ou acceptation
        officielle par l'Organisation des Nations Unies.
        <br />
        La frontière entre la République du Soudan et la République du Soudan du
        Sud n’a pas été déterminée. <br />
        (S) Los Límites y los nombres y las designaciones que figuran en este
        mapa no implican su apoyo o aceptación oficial por las Naciones Unidas.
        <br />
        (A)إن الحدود والأسماء الواردة في هذه الخريطة والتسميات المستخدمة فيها لا
        تعني إقرارا أو قبولا رسميا لها من طرف الأمم المتحدة.
        <br />
        (C)
        本地图所示边界和名称以及采用的说明性注记并不意味着它们已得到联合国的正式认可或接受。
        <br />
        (R) Показанные границы и географические названия и используемые
        обозначения не подразумевают официального согласования или признания
        Организацией Объединенных Наций.
        <br />
      </Popover.Body>
    </Popover>
  );

  const oecd_url =
    region === "eca"
      ? "https://sim.oecd.org/Default.ashx?lang=En&ds=DGSTRI&d1c=af&cs=af"
      : region === "escap"
      ? "https://sim.oecd.org/Default.ashx?lang=En&ds=DGSTRI&d1c=apf&cs=apf"
      : "https://sim.oecd.org/Default.ashx?lang=En&ds=DGSTRI&d1c=am&cs=am";

  return (
    <PageTitle title={t("TR.title", { ns: region })}>
      <Container fluid>
        <p className="fs-5 px-4 pt-2 text-center">
          {t("TR.intro", { ns: region })}
        </p>
        <Row className="mx-2">
          <Col>
            <RegionMap
              setCountryCode={handleCountryChange}
              countryScores={activeCountries}
            />
            <OverlayTrigger trigger="click" placement="right" overlay={popover}>
              <Button variant="secondary" className="btn-sm">
                {t("map_disclaimer", { ns: region })}
              </Button>
            </OverlayTrigger>
            <div className="d-flex flex-column align-items-center bg-light pt-2">
              {t("TR.oecd_message", { ns: region })}&nbsp;
              <a href={oecd_url} target="_blank" rel="noreferrer">
                {t("Links.OECD_Simulator", { ns: region })}
              </a>
            </div>
          </Col>
          <Col>
            <Row xs={1} md={2} className="justify-content-between bg-light">
              {countryCode && (
                <>
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    {countryInfo.validated && (
                      <TiSocialVimeoCircular
                        size={60}
                        style={{
                          marginRight: "10px",
                          backgroundColor: "transparent",
                          color: "green",
                        }}
                      />
                    )}
                    <h4 className="w-100 pt-2">{names[countryCode]}</h4>
                  </div>
                  <Img
                    src={require(`../../assets/pngFlags/${countryCode.toLowerCase()}.svg`)}
                    alt={names[countryCode]}
                  />
                </>
              )}
            </Row>
            {countryCode ? (
              <>
                <Row className="my-1">
                  <>
                    {region == "eca" ? (
                      <div className="alert alert-warning fs-10" role="alert">
                        {t("TR.validated_note", { ns: region })}
                      </div>
                    ) : (
                      ""
                    )}
                    <YearSlider
                      maxYear={maxYear}
                      minYear={minYear}
                      onYearChange={handleForYear}
                    />
                  </>
                  <div
                    className="alert alert-success my-2"
                    role="alert"
                    style={{ fontSize: "15px" }}
                  >
                    {t("TR.overal_score_note", { ns: region })}
                  </div>
                </Row>

                <p className="fst-italic px-2 pt-1">
                  {t("TR.show_pillar", { ns: region })}
                </p>

                <Row xs={1} md={2} className="g-4">
                  <Scores
                    scores={selectedCountry.filter(
                      (tr) => tr.year === parseInt(forYear)
                    )}
                    setPill={(code) =>
                      dispatch({ type: "PILL", payload: code })
                    }
                    valid={countryInfo.validated ? "true" : "false"}
                  />
                </Row>
              </>
            ) : (
              <Callout message={t("TR.message", { ns: region })} />
            )}
            <Row className="mt-3">
              <Col>
                {countryCode && pill && (
                  <Pillar
                    color={countryInfo.validated ? "success" : "primary"}
                    pillar={langPillars.find((p) => p.code === pill)}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </PageTitle>
  );
};

export default TradeRestriction;
