import { Card } from "react-bootstrap";
import { getIcon } from "../util/getIcon";

const Pillar = ({ color, pillar }) => {
  const { body, code, header } = pillar;
  const renderP1 = () => {
    return (
      <>
        <div>{body.intro}</div>
        <ul>
          <li>{body.bullets[0]}</li>
          <li>{body.bullets[1]}</li>
          <li>{body.bullets[2]}</li>
          <li>{body.bullets[3]}</li>
        </ul>
        <div>{body.body}</div>
      </>
    );
  };

  if (code === "DG.0" || !pillar) return null;

  return (
    <Card border={color} className="w-100">
      <Card.Header>
        {getIcon(code)}
        {header}
      </Card.Header>
      <Card.Body>
        <div>{code === "DG.1" ? renderP1() : body}</div>
      </Card.Body>
    </Card>
  );
};

export default Pillar;
