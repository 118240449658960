import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

export const PillerLink = styled(Button)`
  text-align: left;
  color: ${({ theme: { colors } }) => colors.Grey};
`;

export const PillarItem = styled.div`
  /* margin: 10px 0;
  padding: 10px; */
  border-radius: 10px;
  cursor: pointer;
  -webkit-box-shadow: ${({ theme: { shadows } }) => shadows.BoxShadow};
  box-shadow: ${({ theme: { shadows } }) => shadows.BoxShadow};
`;

export const PillarTitle = styled.span`
  font-size: ${({ theme: { fontSizes } }) => fontSizes.medium};
`;

export const Img = styled.img`
  width: 20%;
`;
