import styled, { keyframes } from 'styled-components';

const Callout = ({ message }) => {
  return (
    <CalloutCtr>
      <Arrow href="#" className="left">
        Up
      </Arrow>
      <p className="fs-5 d-flex justify-content-center w-75 text-center">
        {message}
      </p>
      <Arrow href="#" className="right">
        Down
      </Arrow>
    </CalloutCtr>
  );
};

const UpArrow = keyframes`
0% { -webkit-transform: translateX(0); opacity: 0.4 }
100% { -webkit-transform: translateX(-0.9em); opacity: 0.9 }
`;
const DownArrow = keyframes`
0% { -webkit-transform: translateX(0); opacity: 0.4 }
100% { -webkit-transform: translateX(0.9em); opacity: 0.9 }
`;

const CalloutCtr = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 500px;
`;

const Arrow = styled.a`
  border-color: transparent;
  border-style: solid;
  border-width: 2em 0;
  display: block;
  height: 0;
  margin: 10em auto;
  opacity: 0.4;
  text-indent: -9999px;
  transform-origin: 50% 50%;
  width: 0;

  &.left {
    -webkit-animation: ${UpArrow} 0.6s infinite alternate ease-in-out;
    border-right: 2em solid ${({ theme: { colors } }) => colors.Blue};
  }

  &.right {
    -webkit-animation: ${DownArrow} 0.6s infinite alternate ease-in-out;
    border-left: 2em solid ${({ theme: { colors } }) => colors.Blue};
  }
`;

export default Callout;
