import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { NavLink as Link } from "react-router-dom";
import { useNavbar, useLandingNavbar } from "../hooks/useNavbar";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { DtriContext } from "../context/dtri";

const NavbarBs = () => {
  const { t } = useTranslation(["common", "eca", "escap", "eclac"]);
  const { icons } = useNavbar();
  const { landing_icons } = useLandingNavbar();
  const { region, handleRegion } = useContext(DtriContext);

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Navbar.Brand href="#home" className="text-secondary px-5 fs-6">
        {region === "eca"
          ? landing_icons[1].icon
          : region === "escap"
          ? landing_icons[2].icon
          : landing_icons[3].icon}{" "}
        {t("TR.map_title", { ns: region })}
      </Navbar.Brand>
      <Container fluid>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto gap-4">
            <NavLink to={icons[1].path}>
              {icons[1].icon}
              {t("Navbar.home", { ns: region })}
            </NavLink>
            <NavDropdown
              title={t("navbar_dropdown", { ns: region })}
              id="collasible-nav-dropdown"
              menuVariant="dark"
            >
              <NavLinkWrap>
                <NavLink to={icons[2].path}>
                  {t("Navbar.trade_integration", { ns: region })}
                </NavLink>
              </NavLinkWrap>
              <NavLinkWrap>
                <NavLink to={icons[3].path}>
                  {t("Navbar.trade_restriction", { ns: region })}
                </NavLink>
              </NavLinkWrap>
            </NavDropdown>
            <NavLink to={icons[4].path}>
              {icons[4].icon}
              {t("Navbar.country_profile", { ns: region })}
            </NavLink>
            {/* <NavLink to={icons[5].path}>
              {icons[5].icon}
              {t('Navbar.partners_initiative', { ns: region })}
            </NavLink> */}
            <NavLink to={icons[5].path}>
              {icons[5].icon}
              {t("Navbar.additional_resources", { ns: region })}
            </NavLink>
            {/* <NavLink to={icons[7].path}>
              {icons[7].icon}
              {t('Navbar.contact_us', { ns: region })}
            </NavLink> */}
          </Nav>
          <Nav>
            <NavLink to={icons[0].path} onClick={() => handleRegion("common")}>
              {icons[6].icon}
              {t("Navbar.backhome", { ns: region })}
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const NavLinkWrap = styled.div`
  min-width: 500px;
  border-radius: 5px;
  margin: 10px;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.Blue};
    color: ${({ theme: { colors } }) => colors.White};
    border-radius: 5px;
  }

  > a {
    text-decoration: "none";
    color: "white";
  }
`;

const NavLink = styled(Link)`
  color: ${({ theme: { colors } }) => colors.White};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.6rem;
  cursor: pointer;
  gap: 0.4rem;
  font-size: 0.9em;
  /* margin-right: 0.8rem; */

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.Blue};
    color: ${({ theme: { colors } }) => colors.White};
    border-radius: 5px;
  }

  &.active {
    background-color: ${({ theme: { colors } }) => colors.Blue};
    color: ${({ theme: { colors } }) => colors.White};
    border-radius: 5px;
  }
`;

export default NavbarBs;
