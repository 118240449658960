import { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { parse } from "papaparse";

import uploadIcon from "../../assets/images/upload.svg";
import TIData from "../../components/TIData";
import PapaDropFiles from "../../components/PapaDropFiles";
import { createAPIEndpoint, ENDPOINTS } from "../../api";
import { ThemeCtx } from "../../context";
import { HomeCtr } from "./ParseStyles";
import AuthContext from "../../context/AuthProvider";

const ParseTi = () => {
  const [selected, setSelected] = useState(false);
  const [pillars, setPillars] = useState([]);
  const [fileName, setFileName] = useState("");
  const [posted, setPosted] = useState(false);

  const { closed, theme } = useContext(ThemeCtx);
  const [region, setRegion] = useState();
  const { auth } = useContext(AuthContext);

  const ParseData = (tidata) => {
    let parsed = [];
    let cc = "";
    let dd = "";
    const reg = /^-?\d+\.?\d*$/;
    tidata.forEach((ti) => {
      cc = ti.Code?.toString() || ti.Description?.toString();
      dd = reg.test(ti.Description) ? ti.Category : ti.Description;
      if (!cc && !dd) {
        cc = "00";
        dd = "Overall Score";
      }
      delete ti.Code;
      delete ti.Description;
      delete ti.Category;
      delete ti.Weight;
      delete ti["Criteria for scoring"];
      delete ti["Scores (0 to 1)"];

      for (const key in ti) {
        parsed.push({
          region: region,
          code: cc,
          description: dd,
          isoa3: `${key}`,
          score: ti[key] || 0,
        });
      }
    });
    console.log(parsed);
    return parsed;
  };

  const handleChange = (file) => {
    parse(file, {
      header: true,
      dynamicTyping: false,
      complete: ({ data }) => {
        const parsed = ParseData(data);
        setPillars(parsed);
        setPosted(false);
        setFileName(`${file.name}- (${Math.floor(file.size / 1000)} KB)`);
      },
    });
  };

  const postData = async () => {
    try {
      if (region === "-1") {
        setPosted(false);
        return false;
      }
      const toPost = pillars.map(({ id, ...pillars }) => pillars);
      const { data } = await createAPIEndpoint(
        ENDPOINTS.PILLARTI,
        auth.accessToken
      ).post(toPost);
      console.log(toPost);
      if (data) {
        setPosted(data);
      }
    } catch (error) {
      console.log("Unsuccessful posting of pillars data", error);
    }
  };

  const handleRegionChange = (e) => {    
    const newRegion = e.target.value;
    const updatedPillars = pillars.map(pillar => ({ ...pillar, region: newRegion }));
    setPillars(updatedPillars); // Update pillars with the new region
    setRegion(newRegion); // Update the region state
  };

  return (
    <HomeCtr closed={closed}>
      <h1
        className={`my-2 text-center fs-2 ${
          theme === "dark" ? "text-white" : ""
        } `}
      >
        Drop Trade Integration data file here on CSV format
      </h1>
      <PapaDropFiles
        selected={selected}
        setSelected={setSelected}
        handleChange={handleChange}
      />
      <Row>
        <Col>
          <Form.Select
            aria-label="Select the region..."
            value={region}
            onChange={handleRegionChange}
          >
            <option value="">Select the region...</option>
            <option value="eca">ECA</option>
            <option value="escap">ESCAP</option>
            <option value="eclac">ECLAC</option>
          </Form.Select>
        </Col>
        <Col>
          {!posted && (
            <h6 className="text-danger d-flex align-items-center fst-italic small ">
              You cannot click the upload button without selecting the
              corresponding region.
            </h6>
          )}
        </Col>
        <Col>
          <Button
            disabled={!pillars.length || posted || !region}
            className={`d-flex align-items-center gap-3 ${
              pillars.length ? "bg-success" : "bg-secondary"
            }`}
            onClick={postData}
          >
            <img
              src={uploadIcon}
              alt="Upload icon"
              width="25"
              height="25"
              style={{ cursor: "pointer" }}
            />
            Upload data
          </Button>
          {posted && (
            <h3 className="text-success">{`${posted} (${pillars.length} rows)`}</h3>
          )}
        </Col>
      </Row>
      <TIData pillars={pillars} filename={fileName} />
    </HomeCtr>
  );
};

export default ParseTi;
