import PageTitle from "../../components/common/PageTitle";
import { Container } from "./ContactUsStyles";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { DtriContext } from "../../context/dtri";
import { Row, Col, Card } from "react-bootstrap";
import ecaLogo from "../../assets/images/ecalogo.png";
import escapLogo from "../../assets/images/escap-logo.png";
import eclacLogo from "../../assets/images/logo_eclac_en.svg";

const logoStyle = {
  width: "35%",
  marginTop: 10,
  marginLeft: 10,
  alignSelf: "center",
};

const ContactUs = () => {
  const { t } = useTranslation(["common", "eca", "escap", "eclac"]);
  const { region } = useContext(DtriContext);
  return (
    <PageTitle title={t("ContactUs.title", { ns: region })}>
      <Container>
        <Row xs={1} md={3} className="g-2">
          <Col>
            <Card border="primary" className="text-center h-100">
              <Card.Header>Africa</Card.Header>
              <Card.Img variant="top" src={ecaLogo} style={logoStyle} />
              <Card.Body>
                <Card.Text>
                  {t("ContactUs.contact_us_eca_msg", { ns: region })}
                </Card.Text>
                <Card.Link href="mailto:eca-atpc@un.org" target={"_blank"}>
                  {t("ContactUs.contact_us_eca_email", { ns: region })}
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card border="success" className="text-center h-100">
              <Card.Header>Asia Pacific</Card.Header>
              <Card.Img variant="top" src={escapLogo} style={logoStyle} />
              <Card.Body>
                <Card.Text>
                  {t("ContactUs.contact_us_escap_msg", { ns: region })}
                </Card.Text>
                <Card.Link href="mailto:escap-tiid@un.org" target={"_blank"}>
                  {t("ContactUs.contact_us_escap_email", { ns: region })}
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card border="danger" className="text-center h-100">
              <Card.Header>Latin America & Caribbean</Card.Header>
              <Card.Img
                variant="top"
                src={eclacLogo}
                style={{
                  width: "20%",
                  marginTop: 10,
                  marginLeft: 10,
                  alignSelf: "center",
                }}
              />
              <Card.Body>
                <Card.Text>
                  {t("ContactUs.contact_us_eclac_msg", { ns: region })}
                </Card.Text>
                <Card.Link href="mailto:nanno.mulder@un.org" target={"_blank"}>
                  {t("ContactUs.contact_us_eclac_email", { ns: region })}
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* <Table>
          <Row>
            <Col><p>{t('ContactUs.contact_us_title_msg', { ns: region })}</p></Col>
          </Row>
        <Row>
          <Col><p>{t('ContactUs.contact_us_eca_msg', { ns: region })}</p></Col>
          <Col><p>{t('ContactUs.contact_us_escap_msg', { ns: region })}</p></Col>
          <Col><p>{t('ContactUs.contact_us_eclac_msg', { ns: region })}</p></Col>
        </Row>
        </Table>      */}
      </Container>
    </PageTitle>
  );
};

export default ContactUs;
