import {
  MdPayments,
  MdRepeat,
  MdCastConnected,
  MdCopyright,
  MdOutlineAppBlocking,
  MdSummarize,
} from "react-icons/md";

const iconStyle = { marginRight: "5px" };
const iconSize = 22;

export const getIcon = (pillarCode) => {
  console.log(pillarCode);
  switch (pillarCode) {
    case "DG.0":
      return <MdSummarize size={iconSize} style={iconStyle} />;
    case "TR00":
      return <MdSummarize size={iconSize} style={iconStyle} />;
    case "TR01":
      return <MdCastConnected size={iconSize} style={iconStyle} />;
    case "DG.1":
      return <MdCastConnected size={iconSize} style={iconStyle} />;
    case "TR02":
      return <MdRepeat size={iconSize} style={iconStyle} />;
    case "DG.2":
      return <MdRepeat size={iconSize} style={iconStyle} />;
    case "TR03":
      return <MdPayments size={iconSize} style={iconStyle} />;
    case "DG.3":
      return <MdPayments size={iconSize} style={iconStyle} />;
    case "TR04":
      return <MdCopyright size={iconSize} style={iconStyle} />;
    case "DG.4":
      return <MdCopyright size={iconSize} style={iconStyle} />;
    case "TR05":
      return <MdOutlineAppBlocking size={iconSize} style={iconStyle} />;
    case "DG.5":
      return <MdOutlineAppBlocking size={iconSize} style={iconStyle} />;
    case "TR06":
      return <MdCastConnected size={iconSize} style={iconStyle} />;
    case "TR07":
      return <MdRepeat size={iconSize} style={iconStyle} />;
    case "TR08":
      return <MdPayments size={iconSize} style={iconStyle} />;
    case "TR09":
      return <MdCopyright size={iconSize} style={iconStyle} />;
    case "TR10":
      return <MdOutlineAppBlocking size={iconSize} style={iconStyle} />;
    case "TR11":
      return <MdOutlineAppBlocking size={iconSize} style={iconStyle} />;
    default:
      break;
  }
};
