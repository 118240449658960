import React, { useState, useContext } from "react";
import { ThemeCtx } from "../../context";
import FormInput from "./FormInput";
import { LoginCtr, ChangePwdMsg } from "./LoginFormStyles";
import { createAPIEndpoint, ENDPOINTS } from "../../api";
import AuthContext from "../../context/AuthProvider";

const ResetPassword = () => {
  const [pwd, setPwd] = useState({ changed: false, msg: "" });
  const { closed } = useContext(ThemeCtx);
  const [values, setValues] = useState({
    username: "",
    oldpwd: "",
    newpwd: "",
    conformPwd: "",
  });
  const { auth } = useContext(AuthContext);

  const inputs = [
    {
      id: 1,
      name: "username",
      type: "text",
      placeholder: "User Name",
      label: "User Name",
    },
    {
      id: 2,
      name: "newpwd",
      type: "password",
      placeholder: "New Password",
      // errorMessage:
      //   'Password should have at least one letter one number and one special char minimum 6 letters',
      label: "New Password",
      // pattern:
      //   '^(?=.*[a-zA-Z])(?=.*d)(?=.*[!@#$%^&*()_+])[A-Za-zd][A-Za-zd!@#$%^&*()_+]{7,19}$',
      required: true,
    },
    {
      id: 3,
      name: "confirmPwd",
      type: "password",
      placeholder: "Confirm New Password",
      errorMessage: "New Passwords don't match",
      label: "Confirm New Password",
      required: true,
      pattern: values.newpwd,
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const pwd = JSON.stringify({
        username: values.username,
        newPwd: values.newpwd,
      });
      const { data } = await createAPIEndpoint(
        ENDPOINTS.RESETPWD,
        auth.accessToken
      ).resetpwd(pwd);
      if (data)
        setPwd({
          changed: data,
          msg: "Password has been successfuly reset!",
        });
      else setPwd({ changed: data, msg: "Unsuccessful password reset!!" });
    } catch (error) {
      console.log("Unsuccessful posting", error);
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <LoginCtr closed={closed}>
      <form onSubmit={handleSubmit}>
        <h2>Reset Password</h2>
        {inputs.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={values[input.name]}
            onChange={handleChange}
          />
        ))}
        <button>Submit</button>
        {pwd.msg && (
          <ChangePwdMsg changed={pwd.changed}>{pwd.msg}</ChangePwdMsg>
        )}
      </form>
    </LoginCtr>
  );
};

export default ResetPassword;
