import { Fragment } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { DtriContext } from "../context/dtri";

const InfoRow = ({
  reverse,
  darkBg,
  image,
  alt,
  body,
  body2,
  ismap,
  isregions,
  eca_src,
  escap_src,
  eclac_src,
}) => {
  // styles classes and colors
  const rowClass = `p-4 ${darkBg ? "bg-secondary" : ""} ${
    reverse ? "flex-row-reverse" : ""
  }`;
  const headingClass = `fs-2 ${darkBg ? "text-white" : "text-secondary"}`;
  const subTitleClass = `fs-4 ${darkBg ? "text-white" : "text-secondary"}`;
  const bodyTextClass = `mt-2 ${darkBg ? "text-white" : "text-secondary"}`;
  const bulletsClass = `${darkBg ? "text-white" : "text-secondary"}`;
  const titleClass = `mt-2 text-uppercase ${
    darkBg ? "text-warning" : "text-success"
  }`;

  const Heading = (text) => <p className={headingClass}>{text}</p>;
  const Title = (text) => <h6 className={titleClass}>{text}</h6>;

  const SubTitle = (text) => <p className={subTitleClass}> - {text}</p>;
  const Bullets = (text) =>
    text.map((bull, i) => (
      <ul key={i} className="m-0">
        <li className={bulletsClass}>{bull}</li>
      </ul>
    ));

  const BodyText = (text) => <p className={bodyTextClass}>{text}</p>;
  const Lines = (text) =>
    text.map((l, i) => (
      <p key={i} className={bulletsClass}>
        {l}
      </p>
    ));

  const renderBody = (bodyText) => {
    return bodyText.map((item, idx) => (
      <Fragment key={idx}>
        {item.type === "heading" && Heading(item.content)}
        {item.type === "title" && Title(item.content)}
        {item.type === "subtitle" && SubTitle(item.content)}
        {item.type === "bull" && Bullets(item.content)}
        {item.type === "text" && BodyText(item.content)}
        {item.type === "lines" && Lines(item.content)}
      </Fragment>
    ));
  };

  const { t } = useTranslation(["common", "eca", "escap", "eclac"]);
  const { region, handleRegion } = useContext(DtriContext);

  const renderRegion = (regionCode) => {
    return regionCode === "eca" ? (
      <Link
        to="/eca/home"
        onClick={() => {
          handleRegion("eca");
          i18n.changeLanguage("en");
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      >
        <img className="img-fluid" src={eca_src} alt="ECA" />
      </Link>
    ) : regionCode === "escap" ? (
      <Link
        to="/escap/home"
        onClick={() => {
          handleRegion("escap");
          i18n.changeLanguage("en");
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      >
        <img className="img-fluid" src={escap_src} alt="ESCAP" />
      </Link>
    ) : (
      <Link
        to="/eclac/home"
        onClick={() => {
          handleRegion("eclac");
          i18n.changeLanguage("en");
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
      >
        <img className="img-fluid" src={eclac_src} alt="ECLAC" />
      </Link>
    );
  };

  const cols = body2 ? 6 : 8;

  const popover = (
    <Popover id="popover-basic" style={{ maxWidth: 400 }}>
      <Popover.Header as="h3">Map Disclaimer Text</Popover.Header>
      <Popover.Body>
        <strong>LIST OF MAP DISCLAIMERS (E, F, S, A, C, R)</strong>
        <br />
        Short Disclaimer:
        <br />
        (E) The boundaries and names shown and the designations used on this map
        do not imply official endorsement or acceptance by the United Nations.
        <br />
        Final boundary between the Republic of Sudan and the Republic of South
        Sudan has not yet been determined.
        <br />
        (F) Les frontières et les noms indiqués et les désignations employées
        sur cette carte n'impliquent pas reconnaissance ou acceptation
        officielle par l'Organisation des Nations Unies.
        <br />
        La frontière entre la République du Soudan et la République du Soudan du
        Sud n’a pas été déterminée. <br />
        (S) Los Límites y los nombres y las designaciones que figuran en este
        mapa no implican su apoyo o aceptación oficial por las Naciones Unidas.
        <br />
        (A)إن الحدود والأسماء الواردة في هذه الخريطة والتسميات المستخدمة فيها لا
        تعني إقرارا أو قبولا رسميا لها من طرف الأمم المتحدة.
        <br />
        (C)
        本地图所示边界和名称以及采用的说明性注记并不意味着它们已得到联合国的正式认可或接受。
        <br />
        (R) Показанные границы и географические названия и используемые
        обозначения не подразумевают официального согласования или признания
        Организацией Объединенных Наций.
        <br />
      </Popover.Body>
    </Popover>
  );
  // console.log(image)
  if (isregions) {
    return (
      <Container fluid>
        <Row className={rowClass}>
          <Col className="p-2">{renderBody(body)}</Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col sm="2" className="p-2">
            {renderRegion("eca")}
          </Col>
          <Col sm="2" className="p-2">
            {renderRegion("escap")}
          </Col>
          <Col sm="2" className="p-2">
            {renderRegion("eclac")}
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col sm="2" className="p-2 text-center">
            <h6 className={titleClass}>{t("Navbar.eca", { ns: region })}</h6>
          </Col>
          <Col sm="2" className="p-2 text-center">
            <h6 className={titleClass}>{t("Navbar.escap", { ns: region })}</h6>
          </Col>
          <Col sm="2" className="p-2 text-center">
            <h6 className={titleClass}>{t("Navbar.eclac", { ns: region })}</h6>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <Container fluid>
        <Row className={rowClass}>
          {body2 || image ? (
            <>
              <Col sm={cols} className="p-2">
                {renderBody(body)}
              </Col>
              <Col className="p-2 align-self-center">
                {body2 ? (
                  renderBody(body2)
                ) : (
                  <img className="img-fluid" src={image} alt={alt} />
                )}
                {ismap ? (
                  <OverlayTrigger
                    trigger="click"
                    placement="right"
                    overlay={popover}
                  >
                    <Button variant="secondary" className="btn-sm">
                      {t("map_disclaimer", { ns: region })}
                    </Button>
                  </OverlayTrigger>
                ) : (
                  ""
                )}
              </Col>
            </>
          ) : (
            <Col sm="12" className="p-2">
              {renderBody(body)}
            </Col>
          )}
        </Row>
      </Container>
    );
  }
};

export default InfoRow;
