import styled from "styled-components";
import Card from "react-bootstrap/Card";
import { CardGroup } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { DtriContext } from "../../context/dtri";

function PubCard({
  colorIdx,
  pubFileNameEn,
  pubNameEn,
  pubDescriptionEn,
  pubFileNameFr,
  pubNameFr,
  pubDescriptionFr,
  pubFileNameSp,
  pubNameSp,
  pubDescriptionSp,
}) {
  const { region } = useContext(DtriContext);
  const { i18n } = useTranslation();
  const retColor = () =>
    colorIdx === 0
      ? "danger"
      : colorIdx === 1
      ? "success"
      : colorIdx === 2
      ? "primary"
      : "warning";

  const iconStyle = { marginRight: 10, color: "blue" };
  const pdfFile =
    i18n.language === "en"
      ? `${pubFileNameEn}`
      : i18n.language === "fr"
      ? `${pubFileNameFr}`
      : `${pubFileNameSp}`;
  const pName =
    i18n.language === "en"
      ? `${pubNameEn}`
      : i18n.language === "fr"
      ? `${pubNameFr}`
      : `${pubNameSp}`;
  const pDesc =
    i18n.language === "en"
      ? `${pubDescriptionEn}`
      : i18n.language === "fr"
      ? `${pubDescriptionFr}`
      : `${pubDescriptionSp}`;
  // ESCAP-2022-RP-Asia-Pacific-digital-trade-regulatory-review.pdf
  // ESCAP-2021-MN-Regional-digital-trade-integration-index-guidelines.pdf
  return (
    <CardGroup>
      <CardCtr
        border={retColor()}
        style={{ width: "18rem", minHeight: "12rem" }}
      >
        <Card.Header>
          <Card.Link
            href={
              region === "escap"
                ? `${pdfFile}`
                : process.env.REACT_APP_PUBLIC_URL +
                  `/assets/data/publications/${pdfFile}`
            }
            target="_blank"
          >
            <FaDownload style={iconStyle} />
            {pName}
          </Card.Link>
        </Card.Header>
        <Card.Body>
          <Card.Text>{pDesc}</Card.Text>
        </Card.Body>
      </CardCtr>
    </CardGroup>
  );
}

const CardCtr = styled(Card)`
  display: flex;
  justify-content: center;
  border-radius: 10px;
  transform: scale(1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-box-shadow: ${({ theme: { shadows } }) => shadows.BoxShadow2};
  box-shadow: ${({ theme: { shadows } }) => shadows.BoxShadow2};
  min-height: 18rem;
  min-width: 19rem;

  &:hover {
    transform: scale(1.05);
    -webkit-box-shadow: ${({ theme: { shadows } }) => shadows.BoxShadow};
    box-shadow: ${({ theme: { shadows } }) => shadows.BoxShadow};
  }
`;

export default PubCard;
