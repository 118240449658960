import { Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

const Unauthorized = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <article style={{ padding: "100px" }}>
      <h1>Unauthorized</h1>
      <br />
      <p>You do not have access to the requested page.</p>
      <div className="flexGrow">
        <Button onClick={goBack}>Go Back</Button>
      </div>
      <br />
      <div className="flexGrow">
        <h6>or</h6>
        <Link to="/home">Visit Our Homepage</Link>
      </div>
    </article>
  );
};

export default Unauthorized;
