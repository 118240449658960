import { useContext } from "react";
import { MdOutlineCloudUpload } from "react-icons/md";
import { ThemeCtx } from "../context";

const PapaDropFiles = ({ selected, setSelected, handleChange }) => {
  const { theme } = useContext(ThemeCtx);
  return (
    <div
      className={`d-flex flex-column gap-1 align-items-center justify-content-center p-4 my-3 mx-auto border border-1 rounded-2 ${
        selected
          ? "border-2 border-green bg-info text-white bg-gradient"
          : `border-1 border-primary ${
              theme === "dark" ? "bg-light" : "bg-dark"
            } bg-gradient `
      }`}
      onDragEnter={() => setSelected(true)}
      onDragLeave={() => setSelected(false)}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => {
        e.preventDefault();
        setSelected(false);
        handleChange(e.dataTransfer.files[0]);
      }}
    >
      <MdOutlineCloudUpload size={40} className="text-secondary" />
      <input
        className="file-upload-input"
        type="file"
        onChange={(e) => handleChange(e.target.files[0])}
        accept="text/csv"
      />
      <h3 className="text-muted">
        Drag and Drop a file here, or click to choose
      </h3>
    </div>
  );
};

export default PapaDropFiles;
