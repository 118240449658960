import { Navbar, Container, Nav } from "react-bootstrap";
import { NavLink as Link } from "react-router-dom";
import { useLandingNavbar } from "../hooks/useNavbar";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { DtriContext } from "../context/dtri";
import i18n from "i18next";

const LandingNavbarBs = () => {
  const { t } = useTranslation(["common", "eca", "escap", "eclac"]);
  const { landing_icons } = useLandingNavbar();
  const { region, handleRegion } = useContext(DtriContext);

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto gap-4">
            <NavLink
              to={landing_icons[0].path}
              onClick={() => handleRegion("common")}
            >
              {landing_icons[0].icon}
              {t("Navbar.home", { ns: region })}
            </NavLink>
            <NavLink
              to={landing_icons[1].path}
              onClick={() => handleRegion("eca")}
            >
              {landing_icons[1].icon}
              {t("Navbar.eca", { ns: region })}
            </NavLink>
            <NavLink
              to={landing_icons[2].path}
              onClick={() => {
                handleRegion("escap");
                i18n.changeLanguage("en");
              }}
            >
              {landing_icons[2].icon}
              {t("Navbar.escap", { ns: region })}
            </NavLink>
            <NavLink
              to={landing_icons[3].path}
              onClick={() => {
                handleRegion("eclac");
                i18n.changeLanguage("en");
              }}
            >
              {landing_icons[3].icon}
              {t("Navbar.eclac", { ns: region })}
            </NavLink>
            <NavLink to={landing_icons[4].path}>
              {landing_icons[4].icon}
              {t("Navbar.partners_initiative", { ns: region })}
            </NavLink>
            <NavLink to={landing_icons[5].path}>
              {landing_icons[5].icon}
              {t("Navbar.contact_us", { ns: region })}
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const NavLink = styled(Link)`
  color: ${({ theme: { colors } }) => colors.White};
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.6rem;
  cursor: pointer;
  gap: 0.4rem;
  font-size: 0.9em;
  /* margin-right: 0.8rem; */

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.Blue};
    color: ${({ theme: { colors } }) => colors.White};
    border-radius: 5px;
  }

  &.active {
    background-color: ${({ theme: { colors } }) => colors.Blue};
    color: ${({ theme: { colors } }) => colors.White};
    border-radius: 5px;
  }
`;

export default LandingNavbarBs;
