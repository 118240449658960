import { useContext } from "react";
import styled, { css } from "styled-components";
import { Card, Col } from "react-bootstrap";
import { getIcon } from "../util/getIcon";
import { useTranslation } from "react-i18next";
import { DtriContext } from "../context/dtri";

const Scores = ({ scores, setPill, valid }) => {
  const { t } = useTranslation(["common", "eca", "escap", "eclac"]);
  const { region } = useContext(DtriContext);
  const langPillars = t("TR.Pillars", { returnObjects: true, ns: region });
  const pillars = scores.map((item) => ({
    ...item,
    ...langPillars.find(({ code }) => code === item.code),
  }));

  return (
    <>
      {pillars.map((pillar, idx) => (
        <Col key={idx}>
          <CardCtr
            code={pillar.code !== "DG.0" ? "true" : "false"}
            border={valid === "true" ? "success" : "primary"}
            onClick={() => setPill(pillar.code)}
          >
            <Card.Header>
              {getIcon(pillar.code)}
              {pillar.header}
            </Card.Header>
            <Card.Body>
              <Card.Title className="fs-2 pt-2 text-center">
                {pillar.score.toLocaleString()}
              </Card.Title>
            </Card.Body>
          </CardCtr>
        </Col>
      ))}
    </>
  );
};

const CardCtr = styled(Card)`
  display: flex;
  justify-content: center;
  cursor: pointer;

  ${(props) =>
    props.code === "true" &&
    css`
      &:hover {
        background-color: ${({ theme: { colors } }) =>
          colors.YellowGreen} !important;
        transition: transform 125ms;
        transform: translateY(-10px);
        -webkit-box-shadow: ${({ theme: { shadows } }) => shadows.BoxShadow};
        box-shadow: ${({ theme: { shadows } }) => shadows.BoxShadow};
      }
    `}
`;
export default Scores;
