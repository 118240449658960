import { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { parse } from "papaparse";
import Form from "react-bootstrap/Form";

import uploadIcon from "../../assets/images/upload.svg";
import PubData from "../../components/PubData";
import PapaDropFiles from "../../components/PapaDropFiles";
import { createAPIEndpoint, ENDPOINTS } from "../../api";
import { ThemeCtx } from "../../context";
import AuthContext from "../../context/AuthProvider";
import { HomeCtr } from "./ParseStyles";

const ParsePub = () => {
  const [selected, setSelected] = useState(false);
  const [publications, setPublications] = useState([]);
  const [fileName, setFileName] = useState("");
  const [posted, setPosted] = useState(false);

  const { closed, theme } = useContext(ThemeCtx);
  const { auth } = useContext(AuthContext);
  const [region, setRegion] = useState();

  const parseData = (pubdata) => {
    let parsed = [];
    pubdata.forEach((pub) => {
      parsed.push({
        region: region,
        pubNameEn: pub.PubNameEn,
        pubNameFr: pub.PubNameFr,
        pubNameSp: pub.PubNameSp,
        pubDescriptionEn: pub.PubDescriptionEn,
        pubDescriptionFr: pub.PubDescriptionFr,
        pubDescriptionSp: pub.PubDescriptionSp,
        pubFileNameEn: pub.PubFileNameEn,
        pubFileNameFr: pub.PubFileNameFr,
        pubFileNameSp: pub.PubFileNameSp,
      });
    });
    return parsed;
  };

  const postData = async () => {
    try {
      if (region === "-1") {
        setPosted(false);
        return false;
      }
      const toPost = publications.map(
        ({ id, ...publications }) => publications
      );
      console.log(toPost);
      const { data } = await createAPIEndpoint(
        ENDPOINTS.PUBLICATION,
        auth.accessToken
      ).post(toPost);
      if (data) {
        setPosted(true);
      }
    } catch (error) {
      console.log("Unsuccessful posting of publications data", error);
    }
  };

  const handleChange = (file) => {
    parse(file, {
      header: true,
      dynamicTyping: true,
      complete: ({ data }) => {
        const parsed = parseData(data);
        setPublications(parsed);
        setPosted(false);
        setFileName(`${file.name}- (${Math.floor(file.size / 1000)} KB)`);
      },
    });
  };
  const handleRegionChange = (e) => {    
    const newRegion = e.target.value;
    const updatedPublications = publications.map(pub => ({ ...pub, region: newRegion }));
    setPublications(updatedPublications);
    setRegion(newRegion);
  };
  return (
    <HomeCtr closed={closed}>
      <h1
        className={`my-2 text-center fs-2 ${
          theme === "dark" ? "text-white" : ""
        } `}
      >
        Drop publications data file here on CSV format
      </h1>
      <PapaDropFiles
        selected={selected}
        setSelected={setSelected}
        handleChange={handleChange}
      />
      <Row>
        <Col>
          <Form.Select
            aria-label="Select the region..."
            value={region}
            onChange={handleRegionChange}
          >
            <option value="">Select the region...</option>
            <option value="eca">ECA</option>
            <option value="escap">ESCAP</option>
            <option value="eclac">ECLAC</option>
          </Form.Select>
        </Col>
        <Col>
          {!posted && (
            <h6 className="text-danger d-flex align-items-center fst-italic small ">
              You cannot click the upload button without selecting the
              corresponding region.
            </h6>
          )}
        </Col>
        <Col>
          <Button
            disabled={!publications.length || posted || !region}
            className={`d-flex align-items-center gap-3 ${
              publications.length ? "bg-success" : "bg-secondary"
            }`}
            onClick={postData}
          >
            <img
              src={uploadIcon}
              alt="Upload icon"
              width="25"
              height="25"
              style={{ cursor: "pointer" }}
            />
            Upload data
          </Button>
          {posted && (
            <h3 className="text-success">{`${posted} (${publications.length} rows)`}</h3>
          )}
        </Col>
      </Row>
      <PubData publications={publications} filename={fileName} />
    </HomeCtr>
  );
};

export default ParsePub;
