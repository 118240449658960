import { useContext } from "react";
import styled from "styled-components";
import { FaDownload } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { DtriContext } from "../../context/dtri";

const DownloadBtn = ({ code, path }) => {
  const { t } = useTranslation(["common", "eca", "escap", "eclac"]);
  const { region } = useContext(DtriContext);
  const countries = t("CountriesA3", { returnObjects: true, ns: region });
  const country = countries[code];

  return (
    <CountryNameLink>
      <Button className="text-white h-100">
        <Href
          href={`${process.env.REACT_APP_PUBLIC_URL}/assets/data/${path}/${code}-RDTII.xlsx`}
          download={`${code}-RDTII.xlsx`}
        >
          <FaDownload />
          {t("TR.download_btn", { countryName: country, ns: region })}
        </Href>
      </Button>
    </CountryNameLink>
  );
};

const CountryNameLink = styled.div`
  img {
    flex: 2;
    margin-right: 5px;
    width: 15%;
    border: 1px solid black;
  }
`;

const Href = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 7px;
  color: ${({ theme: { colors } }) => colors.White};

  &:hover {
    color: ${({ theme: { colors } }) => colors.White};
  }
`;

export default DownloadBtn;
