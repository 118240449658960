import styled from "styled-components";
import PageTitle from "../../components/common/PageTitle";
import { Container, Card, Row, Col } from "react-bootstrap";
import { usePartners } from "../../hooks/usePartners";
import { DtriContext } from "../../context/dtri";
import { useTranslation } from "react-i18next";
import { useContext } from "react";

const RenderLine = (pline) => {
  return (
    <>
      {pline.map((ln) => (
        <Col key={ln.name}>
          <CardCtr>
            {ln.component ? (
              <ImgCtr>{ln.logo}</ImgCtr>
            ) : (
              <Card.Img
                height={ln.name === "eui" ? "215px" : "265px"}
                variant="top"
                src={ln.logo}
              />
            )}
            <Card.Body>
              <Card.Text>
                <a href={ln.link} target="_blank" rel="noreferrer">
                  {ln.header}
                </a>
              </Card.Text>
            </Card.Body>
          </CardCtr>
        </Col>
      ))}
    </>
  );
};

const PartnersInitiative = () => {
  const { region } = useContext(DtriContext);
  const { t } = useTranslation(["common", "eca", "escap", "eclac"]);

  const { pline1, pline2, pline3 } = usePartners();
  return (
    <Container>
      <PageTitle title={t("PI.title", { ns: region })}>
        <Row xs={1} md={2} className="g-4">
          {RenderLine(pline1)}
        </Row>
        <Row xs={1} md={2} className="g-4">
          {RenderLine(pline2)}
        </Row>
        <Row xs={1} md={2} className="g-4">
          {RenderLine(pline3)}
        </Row>
      </PageTitle>
    </Container>
  );
};

const CardCtr = styled(Card)`
  margin: 40px 0;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  transform: scale(1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-box-shadow: ${({ theme: { shadows } }) => shadows.BoxShadow2};
  box-shadow: ${({ theme: { shadows } }) => shadows.BoxShadow2};

  &:hover {
    transform: scale(1.05);
    -webkit-box-shadow: ${({ theme: { shadows } }) => shadows.BoxShadow};
    box-shadow: ${({ theme: { shadows } }) => shadows.BoxShadow};
  }

  > img {
    background-color: ${({ theme: { colors } }) => colors.LightGrey};
  }
`;

const ImgCtr = styled.div`
  display: flex;
  height: 15rem;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.LightGrey};
  border: 0.2px solid ${({ theme: { colors } }) => colors.Grey};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
`;

export default PartnersInitiative;
