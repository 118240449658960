import { useContext, useState, useCallback, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";

import { CountryListCtr } from "./CountryStyles";
import { ThemeCtx } from "../../context";
import { createAPIEndpoint, ENDPOINTS } from "../../api";
import { columns } from "./columns";
import AuthContext from "../../context/AuthProvider";

export default function CountryList() {
  const [rows, setRows] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [loading, setLoading] = useState(true);

  const { closed } = useContext(ThemeCtx);
  const { auth } = useContext(AuthContext);

  useEffect(() => {
    async function fetchCountries() {
      const { data } = await createAPIEndpoint(ENDPOINTS.COUNTRY).fetch();
      setRows(data);
      setLoading(false);
    }
    fetchCountries();
  }, []);

  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = useCallback(
    async (newRow) => {
      // Make the HTTP request to save in the backend
      const { data } = await createAPIEndpoint(
        ENDPOINTS.COUNTRY,
        auth.accessToken
      ).put(newRow.id, newRow);
      console.log("RESPONSE", data);
      setSnackbar({
        children: "Country successfully saved",
        severity: "success",
      });
      return data;
    },
    [auth.accessToken]
  );

  const handleProcessRowUpdateError = useCallback((error) => {
    setSnackbar({ children: error.message, severity: "error" });
  }, []);

  return (
    <CountryListCtr closed={closed}>
      <DataGrid
        components={{
          LoadingOverlay: LinearProgress,
        }}
        loading={loading}
        rows={rows}
        columns={columns}
        pageSize={13}
        rowsPerPageOptions={[13]}
        disableSelectionOnClick
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        experimentalFeatures={{ newEditingApi: true }}
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </CountryListCtr>
  );
}
