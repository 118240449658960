import React from "react";
// import i18n from "i18next";
import { useTranslation } from "react-i18next";
// import cookies from "js-cookie";
import { useContext } from "react";
import { DtriContext } from "../../context/dtri";

import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinkItems,
  FooterLinksWrapper,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./FooterStyles";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
  FaFlickr,
  FaRss,
} from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";

const renderFooterCol = (fcol) => {
  return (
    <>
      <FooterLinkTitle fontWeight="900">{fcol[0].title}</FooterLinkTitle>
      {fcol.map(
        (fc, idx) =>
          idx !== 0 && (
            <FooterLink key={idx} href={fc.path} target="_blank">
              {fc.title}
            </FooterLink>
          )
      )}
    </>
  );
};

const Footer = () => {
  const { t } = useTranslation(["common", "eca", "escap", "eclac"]);
  const { region } = useContext(DtriContext);

  const about = t("Footer.about", { ns: region, returnObjects: true });
  const opportunities = t("Footer.opportunities", {
    ns: region,
    returnObjects: true,
  });
  const resources = t("Footer.resources", { ns: region, returnObjects: true });
  const more = t("Footer.more", { ns: region, returnObjects: true });

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  if (region === "eca") {
    return (
      <>
        <FooterContainer>
          <FooterWrap>
            <FooterLinksContainer>
              <FooterLinksWrapper>
                <FooterLinkItems>{renderFooterCol(about)}</FooterLinkItems>
                <FooterLinkItems>
                  {renderFooterCol(opportunities)}
                </FooterLinkItems>
                <FooterLinkItems>{renderFooterCol(resources)} </FooterLinkItems>
                <FooterLinkItems>{renderFooterCol(more)} </FooterLinkItems>
              </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialMedia>
              <SocialMediaWrap>
                <SocialLogo to="/" onClick={toggleHome}>
                  <img
                    src={require("../../assets/images/ecalogo.png")}
                    alt="Footer logo"
                    width={150}
                  />
                </SocialLogo>
                <WebsiteRights>
                  &copy; &nbsp; {t("Footer.copy_right", { ns: region })}
                </WebsiteRights>
                <SocialIcons>
                  <SocialIconLink
                    href="/"
                    target="_blank"
                    aria-label="Instagram"
                  >
                    <FaInstagram />
                  </SocialIconLink>
                  <SocialIconLink
                    href="https://m.facebook.com/economiccommissionforafrica?_rdr"
                    target="_blank"
                    aria-label="Facebook"
                  >
                    <FaFacebook />
                  </SocialIconLink>
                  <SocialIconLink
                    href="http://www.youtube.com/unecavideo/"
                    target="_blank"
                    aria-label="Youtube"
                  >
                    <FaYoutube />
                  </SocialIconLink>
                  <SocialIconLink
                    href="https://www.flickr.com/photos/uneca/albums"
                    target="_blank"
                    aria-label="Flickr"
                  >
                    <FaFlickr />
                  </SocialIconLink>
                  <SocialIconLink
                    href="https://twitter.com/eca_official"
                    target="_blank"
                    aria-label="Twitter"
                  >
                    <FaTwitter />
                  </SocialIconLink>
                  <SocialIconLink
                    href="https://www.linkedin.com/company/united-nations-economic-commission-for-africa/"
                    target="_blank"
                    aria-label="Linkedin"
                  >
                    <FaLinkedin />
                  </SocialIconLink>
                  <SocialIconLink
                    href="https://www.uneca.org/rss.xml"
                    target="_blank"
                    aria-label="RSS"
                  >
                    <FaRss />
                  </SocialIconLink>
                </SocialIcons>
              </SocialMediaWrap>
            </SocialMedia>
          </FooterWrap>
        </FooterContainer>
      </>
    );
  } else if (region === "escap") {
    return (
      <>
        <FooterContainer>
          <FooterWrap>
            <FooterLinksContainer>
              <FooterLinksWrapper>
                <FooterLinkItems>{renderFooterCol(about)}</FooterLinkItems>
                <FooterLinkItems>
                  {renderFooterCol(opportunities)}
                </FooterLinkItems>
                <FooterLinkItems>{renderFooterCol(resources)} </FooterLinkItems>
                <FooterLinkItems>{renderFooterCol(more)} </FooterLinkItems>
              </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialMedia>
              <SocialMediaWrap>
                <SocialLogo to="/" onClick={toggleHome}>
                  <img
                    src={require("../../assets/images/escap-logo-white-small.png")}
                    alt="Footer logo"
                    width={150}
                  />
                </SocialLogo>
                <WebsiteRights>
                  &copy; &nbsp; {t("Footer.copy_right", { ns: region })}
                </WebsiteRights>
                <SocialIcons>
                  <SocialIconLink
                    href="https://instagram.com/unitednationsescap/"
                    target="_blank"
                    aria-label="Instagram"
                  >
                    <FaInstagram />
                  </SocialIconLink>
                  <SocialIconLink
                    href="https://m.facebook.com/UNESCAP"
                    target="_blank"
                    aria-label="Facebook"
                  >
                    <FaFacebook />
                  </SocialIconLink>
                  <SocialIconLink
                    href="http://www.youtube.com/unescap/"
                    target="_blank"
                    aria-label="Youtube"
                  >
                    <FaYoutube />
                  </SocialIconLink>
                  <SocialIconLink
                    href="https://www.linkedin.com/company/united-nations-escap"
                    target="_blank"
                    aria-label="Linkedin"
                  >
                    <FaLinkedin />
                  </SocialIconLink>
                </SocialIcons>
              </SocialMediaWrap>
            </SocialMedia>
          </FooterWrap>
        </FooterContainer>
      </>
    );
  } else if (region === "eclac") {
    return (
      <>
        <FooterContainer>
          <FooterWrap>
            <FooterLinksContainer>
              <FooterLinksWrapper>
                <FooterLinkItems>{renderFooterCol(about)}</FooterLinkItems>
                <FooterLinkItems>
                  {renderFooterCol(opportunities)}
                </FooterLinkItems>
                <FooterLinkItems>{renderFooterCol(resources)} </FooterLinkItems>
                <FooterLinkItems>{renderFooterCol(more)} </FooterLinkItems>
              </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialMedia>
              <SocialMediaWrap>
                <SocialLogo to="/" onClick={toggleHome}>
                  <img
                    src={require("../../assets/images/eclac-logo.png")}
                    alt="Footer logo"
                    width={150}
                  />
                </SocialLogo>
                <WebsiteRights>
                  &copy; &nbsp; {t("Footer.copy_right", { ns: region })}
                </WebsiteRights>
                <SocialIcons>
                  <SocialIconLink
                    href="https://m.facebook.com/eclac"
                    target="_blank"
                    aria-label="Facebook"
                  >
                    <FaFacebook />
                  </SocialIconLink>
                  <SocialIconLink
                    href="https://www.youtube.com/user/ECLACUN"
                    target="_blank"
                    aria-label="Youtube"
                  >
                    <FaYoutube />
                  </SocialIconLink>
                  <SocialIconLink
                    href="https://twitter.com/eclac_un"
                    target="_blank"
                    aria-label="Youtube"
                  >
                    <FaTwitter />
                  </SocialIconLink>
                  <SocialIconLink
                    href="https://www.flickr.com/photos/eclac"
                    target="_blank"
                    aria-label="Flickr"
                  >
                    <FaFlickr />
                  </SocialIconLink>
                  <SocialIconLink
                    href="https://www.cepal.org/en/rss.xml"
                    target="_blank"
                    aria-label="RSS"
                  >
                    <FaRss />
                  </SocialIconLink>
                </SocialIcons>
              </SocialMediaWrap>
            </SocialMedia>
          </FooterWrap>
        </FooterContainer>
      </>
    );
  }
  return null;
};

export default Footer;
