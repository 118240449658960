import React, { useContext } from "react";
import { ThemeCtx } from "../../context";

const Home = () => {
  const { theme } = useContext(ThemeCtx);
  return (
    <div className="py-3" style={{ textAlign: "center" }}>
      <h3 className={`${theme === "dark" ? "text-white" : ""}`}>
        Welcome to the DTRI Initiative Admin Area
      </h3>
    </div>
  );
};

export default Home;
