import React, { Suspense } from "react";
// import GlobalStyles from "./GlobalStyles";
import ReactDOM from "react-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from "./context/AuthProvider";
import { ThemeStore } from "./context";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "fr", "es"],
    fallbackLng: "en",
    ns: ["common", "eca", "escap", "eclac"],
    defaultNS: "common",
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/{{ns}}.json",
    },
  });

const loading = (
  <div>
    <h2>Loading...</h2>
  </div>
);

ReactDOM.render(
  <Suspense fallback={loading}>
    <React.StrictMode>
      <BrowserRouter>
        <ThemeStore>
          {/* <GlobalStyles /> */}
          <AuthProvider>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </AuthProvider>
        </ThemeStore>
      </BrowserRouter>
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);
