import i18n from "i18next";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { useContext } from "react";
import { DtriContext } from "../../context/dtri";

import {
  Container,
  Title,
  LanguageCtr,
  LangBtn,
  ECALogo,
  LangFlag,
  LogosCtr,
  Overlay,
  Jumbotron,
  LogoLangCtr,
} from "./HeaderStyles";
import gbflag from "../../assets/flags/1x1/gb.svg";
import frflag from "../../assets/flags/1x1/fr.svg";
import esflag from "../../assets/flags/1x1/es.svg";
import ecaLogo from "../../assets/images/ecalogo-small.png";
import cealogo from "../../assets/images/cealogo-small.png";
import escapLogo from "../../assets/images/escap-logo-white-small.png";
import eclacLogo from "../../assets/images/eclac-logo-small.png";
import eclacLogoSP from "../../assets/images/eclac-logo-es-small.png";
import LandingNavbarBs from "../LandingNavbarBs";

const languages = [
  {
    code: "en",
    name: "English",
    country_flag: gbflag,
  },
  {
    code: "fr",
    name: "Français",
    country_flag: frflag,
  },
  {
    code: "es",
    name: "Español",
    country_flag: esflag,
  },
];

const LandingHeader = () => {
  const { t } = useTranslation(["common", "eca", "escap", "eclac"]);
  const currentLangCode = cookies.get("i18next") || "en";
  const { region } = useContext(DtriContext);

  const ecaLogoImg =
    currentLangCode === "en"
      ? ecaLogo
      : currentLangCode === "fr"
      ? cealogo
      : currentLangCode === "es"
      ? ecaLogo
      : "";

  const escapLogoImg =
    currentLangCode === "en"
      ? escapLogo
      : currentLangCode === "fr"
      ? escapLogo
      : currentLangCode === "es"
      ? escapLogo
      : "";

  const eclacLogoImg =
    currentLangCode === "en"
      ? eclacLogo
      : currentLangCode === "fr"
      ? eclacLogo
      : currentLangCode === "es"
      ? eclacLogoSP
      : "";

  const ecaURL =
    currentLangCode === "en"
      ? "https://www.uneca.org/"
      : currentLangCode === "fr"
      ? "https://www.uneca.org/fr"
      : currentLangCode === "es"
      ? "https://www.uneca.org/"
      : "";

  const escapURL =
    currentLangCode === "en"
      ? "https://www.unescap.org/"
      : currentLangCode === "fr"
      ? "https://www.unescap.org/"
      : currentLangCode === "es"
      ? "https://www.unescap.org/"
      : "";
  const eclacURL =
    currentLangCode === "en"
      ? "https://www.cepal.org/en"
      : currentLangCode === "fr"
      ? "https://www.cepal.org/en"
      : currentLangCode === "es"
      ? "https://www.cepal.org/es"
      : "";

  return (
    <Container>
      <Jumbotron>
        <Overlay />
        <LogoLangCtr>
          <LogosCtr>
            <ECALogo href={ecaURL}>
              <img src={ecaLogoImg} alt="Logo" width="30%" />
            </ECALogo>
            <ECALogo href={escapURL}>
              <img src={escapLogoImg} alt="Logo" width="30%" />
            </ECALogo>
            <ECALogo href={eclacURL}>
              <img src={eclacLogoImg} alt="Logo" width="30%" />
            </ECALogo>
          </LogosCtr>
          <LanguageCtr>
            {languages.map(({ code, name, country_flag }) => (
              <LangBtn key={code}>
                <LangFlag
                  activeLang={code === currentLangCode}
                  src={country_flag}
                  alt={`${name} language flag`}
                />
                <button
                  onClick={() => i18n.changeLanguage(code)}
                  disabled={code === currentLangCode}
                >
                  {name}
                </button>
              </LangBtn>
            ))}
          </LanguageCtr>
        </LogoLangCtr>
        <Title>
          <span>{t("homepage_title", { ns: region })}</span>
        </Title>
      </Jumbotron>
      <LandingNavbarBs />
    </Container>
  );
};

export default LandingHeader;
