import React, { useContext } from "react";
import { Table } from "react-bootstrap";
import { ThemeCtx } from "../context";

const TRData = ({ pillars, filename }) => {
  const { theme } = useContext(ThemeCtx);
  return (
    <div className="py-3">
      <h1 className={`${theme === "dark" ? "text-white" : ""}`}>{filename}</h1>
      <Table responsive="sm" striped bordered hover variant={theme}>
        <thead>
          <tr>
            <th>Isoa3</th>
            <th>Year</th>
            <th>Pillar Code</th>
            <th>Pillar</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          {pillars.map((pillar, index) => (
            <tr key={`${pillar.isoa3}-${index}`}>
              <td>{pillar.isoa3}</td>
              <td>{pillar.year}</td>
              <td>{pillar.pillarCode}</td>
              <td>{pillar.pillarDescrip}</td>
              <td>{pillar.score}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TRData;
