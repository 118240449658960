export const initialState = {
  pillars: [],
  countryCode: '',
  forYear: new Date().getFullYear(),
  pill: null,
  activeCountries: [],
  selectedCountry: [],
  countryInfo: { overAll: 0, validated: false },
};

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'PILLARS':
      return { ...state, pillars: payload };
    case 'ACTIVE_COUNTRIES':
      return { ...state, activeCountries: payload };
    case 'SELECTED_COUNTRY':
      return { ...state, selectedCountry: payload };
    case 'COUNTRY_CODE':
      return { ...state, countryCode: payload };
    case 'COUNTRY_INFO':
      return { ...state, countryInfo: payload };
    case 'FOR_YEAR':
      return { ...state, forYear: payload };
    case 'PILL':
      return { ...state, pill: payload };
    default:
      return state;
  }
};
