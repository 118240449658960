import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { DtriContext } from "../../context/dtri";

import home01 from "../../assets/images/home01.svg";
import home02 from "../../assets/images/home02.svg";
import home03 from "../../assets/images/home03.svg";
import home04 from "../../assets/images/home04.svg";
import home05 from "../../assets/images/home05.svg";
import home06 from "../../assets/images/home06.svg";
// import homemap from "../../assets/images/eca-home-page-map-final.png";
import homemap from "../../assets/images/eca-home-page-map.png";
// import homemap from "../../assets/images/home-page-map.png";
import escaphomemap from "../../assets/images/escap-home-page-map-no-name.png";
import InfoRow from "../../components/InfoRow";

const Home = () => {
  const { region } = useContext(DtriContext);
  const { t } = useTranslation(["common", "eca", "escap", "eclac"]);

  const homeSection1 = {
    reverse: false,
    darkBg: false,
    image:
      region === "eca" ? homemap : region === "escap" ? escaphomemap : home04,
    ismap: region === "eca" ? true : region === "escap" ? true : false,
    alt: "Home Section One",
    body: [
      { type: "heading", content: t("Home.title", { ns: region }) },
      { type: "text", content: t("Home.intro", { ns: region }) },
      { type: "title", content: t("Home.key_objective_title", { ns: region }) },
      { type: "text", content: t("Home.key_objective_body", { ns: region }) },
      region != "eca"
        ? { type: "title", content: t("Home.what_next_title", { ns: region }) }
        : {},
      region != "eca"
        ? { type: "text", content: t("Home.what_next_body", { ns: region }) }
        : {},
      {
        type: "title",
        content: t("Home.country_covered_title", { ns: region }),
      },
      {
        type: "lines",
        content: t("Home.country_covered_body", {
          ns: region,
          returnObjects: true,
        }),
      },
    ],
  };

  const homeSection2 = {
    reverse: false,
    darkBg: true,
    image: null,
    ismap: false,
    alt: "",
    body: [
      { type: "title", content: t("Home.info_packaged_title", { ns: region }) },
      { type: "text", content: t("Home.info_packaged_body", { ns: region }) },
      {
        type: "subtitle",
        content: t("Home.digital_trade_subtitle", { ns: region }),
      },
      { type: "text", content: t("Home.digital_trade_intro", { ns: region }) },
      {
        type: "bull",
        content: t("Home.digital_trade_bullets", { returnObjects: true }),
      },
    ],
    body2: [
      {
        type: "subtitle",
        content: t("Home.digital_services_subtitle", { ns: region }),
      },
      {
        type: "text",
        content: t("Home.digital_services_intro", { ns: region }),
      },
      {
        type: "bull",
        content: t("Home.digital_services_bullets", { returnObjects: true }),
      },
      {
        type: "text",
        content: t("Home.info_packaged_conclusion", { ns: region }),
      },
    ],
  };

  const homeSection3 = {
    reverse: false,
    darkBg: false,
    image: region === "eca" ? home01 : region === "escap" ? home05 : home06,
    ismap: false,
    alt: "Home Section Three",
    body: [
      {
        type: "title",
        content: t("Home.collected_info_title", { ns: region }),
      },
      {
        type: "bull",
        content: t("Home.collected_info_body", {
          ns: region,
          returnObjects: true,
        }),
      },
      { type: "title", content: t("Home.collect_info_title", { ns: region }) },
      {
        type: "bull",
        content: t("Home.collect_info_body", {
          ns: region,
          returnObjects: true,
        }),
      },
    ],
  };

  const homeSection4 = {
    reverse: false,
    darkBg: false,
    image: null,
    ismap: false,
    alt: "",
    body: [
      { type: "title", content: t("Home.get_involved_title", { ns: region }) },
      {
        type: "subtitle",
        content: t("Home.get_involved_government_subtitle", { ns: region }),
      },
      {
        type: "text",
        content: t("Home.get_involved_government", { ns: region }),
      },
      {
        type: "subtitle",
        content: t("Home.get_involved_academic_subtitle", { ns: region }),
      },
      {
        type: "text",
        content: t("Home.get_involved_academic", { ns: region }),
      },
      {
        type: "bull",
        content: t("Home.get_involved_academic_points", {
          returnObjects: true,
          ns: region,
        }),
      },
      {
        type: "text",
        content: t("Home.get_involved_conclusion", { ns: region }),
      },
    ],
  };

  return (
    <>
      <InfoRow {...homeSection1} />
      {region == "escap" ? <InfoRow {...homeSection4} /> : ""}
      <InfoRow {...homeSection2} />
      <InfoRow {...homeSection3} />
    </>
  );
};

export default Home;
