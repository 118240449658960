import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { DtriContext } from "../../context/dtri";

import home02 from "../../assets/images/home02.svg";
import eca_src from "../../assets/images/Africa.png";
import escap_src from "../../assets/images/Asia.png";
import eclac_src from "../../assets/images/Latin_America.png";
import InfoRow from "../../components/InfoRow";

const LandingPage = () => {
  const { region } = useContext(DtriContext);
  const { t } = useTranslation(["common", "eca", "escap", "eclac"]);

  const homeSection1 = {
    reverse: false,
    darkBg: false,
    image: home02,
    ismap: false,
    isregions: false,
    eca_src: null,
    escap_src: null,
    eclac_src: null,
    alt: "",
    body: [
      { type: "heading", content: t("Home.title", { ns: region }) },
      { type: "text", content: t("Home.intro", { ns: region }) },
      { type: "title", content: t("Home.key_objective_title", { ns: region }) },
      { type: "text", content: t("Home.key_objective_body", { ns: region }) },
      // { type: 'title', content: t('Home.country_covered_title', { ns: region }) },
      // {
      //   type: 'lines',
      //   content: t('Home.country_covered_body', { ns: region, returnObjects: true }),
      // },
    ],
  };

  const homeSection2 = {
    reverse: false,
    darkBg: true,
    image: null,
    ismap: false,
    isregions: false,
    eca_src: null,
    escap_src: null,
    eclac_src: null,
    alt: "",
    body: [
      { type: "title", content: t("Home.info_packaged_title", { ns: region }) },
      { type: "text", content: t("Home.info_packaged_body", { ns: region }) },
      {
        type: "subtitle",
        content: t("Home.digital_trade_subtitle", { ns: region }),
      },
      { type: "text", content: t("Home.digital_trade_intro", { ns: region }) },
      {
        type: "bull",
        content: t("Home.digital_trade_bullets", { returnObjects: true }),
      },
    ],
    body2: [
      {
        type: "subtitle",
        content: t("Home.digital_services_subtitle", { ns: region }),
      },
      {
        type: "text",
        content: t("Home.digital_services_intro", { ns: region }),
      },
      {
        type: "bull",
        content: t("Home.digital_services_bullets", { returnObjects: true }),
      },
      {
        type: "text",
        content: t("Home.info_packaged_conclusion", { ns: region }),
      },
    ],
  };

  const homeSection3 = {
    reverse: false,
    darkBg: false,
    image: null,
    ismap: false,
    isregions: true,
    eca_src: eca_src,
    escap_src: escap_src,
    eclac_src: eclac_src,
    alt: "Home Section Three",
    body: [
      {
        type: "title",
        content: t("Home.regions_covered_title", { ns: region }),
      },
      { type: "text", content: t("Home.regions_covered_body", { ns: region }) },
    ],
  };

  return (
    <>
      <InfoRow {...homeSection1} />
      <InfoRow {...homeSection2} />
      <InfoRow {...homeSection3} />
    </>
  );
};

export default LandingPage;
