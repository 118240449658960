import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";

import { Img } from "./TradeIntegrationStyles";
import { DtriContext } from "../../context/dtri";
import {
  PageTitle,
  GaugeChart,
  DownloadBtn,
  Callout,
} from "../../components/common";
import RegionMap from "../../components/RegionMap";
import AccordionPillars from "../../components/AccordionPillars";
import { ENDPOINTS, createAPIEndpoint } from "../../api";

import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const TradeIntegration = () => {
  const [countryCode, setCountryCode] = useState();
  const [pillars, setPillars] = useState([]);
  const [activeCountries, setActiveCountries] = useState([]);
  const [countryInfo, setCountryInfo] = useState({
    overAll: 0,
  });
  const { countries } = useContext(DtriContext);
  const { t } = useTranslation(["common", "eca", "escap", "eclac"]);
  const { region } = useContext(DtriContext);
  const names = t("CountriesA3", { returnObjects: true, ns: region });

  useEffect(() => {
    async function fetchTI() {
      try {
        const { data } = await createAPIEndpoint(ENDPOINTS.PILLARTI).fetch();
        const mapped = data.map((c) => ({
          id: c.id,
          code: c.code,
          isoa3: c.isoa3,
          score: c.score,
        }));
        setPillars(mapped);
        setActiveCountries(
          mapped.filter((f) => f.code === "00").map((c) => [c.isoa3, c.score])
        );
      } catch (error) {
        console.log("Error reading Digital Integration data");
      }
    }
    fetchTI();
  }, []);

  const handleCountryChange = (cntry) => {
    setCountryCode(cntry);
    const country = countries.find((c) => c.isoa3 === cntry) || null;
    const score = pillars.find((s) => s.isoa3 === cntry && s.code === "00");
    if (country)
      setCountryInfo({
        overAll: score.score,
      });
  };

  const popover = (
    <Popover id="popover-basic" style={{ maxWidth: 400 }}>
      <Popover.Header as="h3">Map Disclaimer Text</Popover.Header>
      <Popover.Body>
        <strong>LIST OF MAP DISCLAIMERS (E, F, S, A, C, R)</strong>
        <br />
        Short Disclaimer:
        <br />
        (E) The boundaries and names shown and the designations used on this map
        do not imply official endorsement or acceptance by the United Nations.
        <br />
        Final boundary between the Republic of Sudan and the Republic of South
        Sudan has not yet been determined.
        <br />
        (F) Les frontières et les noms indiqués et les désignations employées
        sur cette carte n'impliquent pas reconnaissance ou acceptation
        officielle par l'Organisation des Nations Unies.
        <br />
        La frontière entre la République du Soudan et la République du Soudan du
        Sud n’a pas été déterminée. <br />
        (S) Los Límites y los nombres y las designaciones que figuran en este
        mapa no implican su apoyo o aceptación oficial por las Naciones Unidas.
        <br />
        (A)إن الحدود والأسماء الواردة في هذه الخريطة والتسميات المستخدمة فيها لا
        تعني إقرارا أو قبولا رسميا لها من طرف الأمم المتحدة.
        <br />
        (C)
        本地图所示边界和名称以及采用的说明性注记并不意味着它们已得到联合国的正式认可或接受。
        <br />
        (R) Показанные границы и географические названия и используемые
        обозначения не подразумевают официального согласования или признания
        Организацией Объединенных Наций.
        <br />
      </Popover.Body>
    </Popover>
  );

  return (
    <PageTitle title={t("TIS.title", { ns: region })}>
      <Container fluid>
        <p className=" fs-5 px-2 pt-2 text-center">
          {t("TIS.intro", { ns: region })}
        </p>
        <Row className="mx-2">
          <Col>
            <RegionMap
              setCountryCode={handleCountryChange}
              countryScores={activeCountries}
              tradeType="TI"
            />
            <OverlayTrigger trigger="click" placement="right" overlay={popover}>
              <Button variant="secondary" className="btn-sm">
                {t("map_disclaimer", { ns: region })}
              </Button>
            </OverlayTrigger>
          </Col>

          {countryCode ? (
            <Col>
              <Row xs={1} md={2} className="justify-content-between bg-light">
                <>
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    <h4 className="w-100 pt-2">{names[countryCode]}</h4>
                  </div>
                  <Img
                    src={require(`../../assets/pngFlags/${countryCode.toLowerCase()}.svg`)}
                    alt={names[countryCode]}
                  />
                </>
              </Row>
              <Row xs={1} md={2} className="my-2">
                <Col className="d-flex align-items-center">
                  <DownloadBtn code={countryCode} path="integration" />
                </Col>
                <Col className="d-flex justify-content-center">
                  <GaugeChart value={countryInfo.overAll} />
                </Col>
              </Row>
              <div
                className="alert alert-success fs-10"
                role="alert"
                style={{ fontSize: "15px" }}
              >
                {t("TIS.overal_score_note", { ns: region })}
              </div>
              <p className="fst-italic px-2 pt-2">
                {t("TIS.show_pillar", { ns: region })}
              </p>

              <Row className="mt-4">
                {/* {countryCode ? ( */}
                <AccordionPillars
                  pillars={pillars.filter((p) => p.isoa3 === countryCode)}
                  color={countryInfo.validated ? "success" : "primary"}
                />
              </Row>
            </Col>
          ) : (
            <Col>
              <Callout message={t("TIS.message", { ns: region })} />
            </Col>
          )}
        </Row>
      </Container>
    </PageTitle>
  );
};

export default TradeIntegration;
