import { useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';

const ScrollTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
  }, []);

  return (
    <>
      {isVisible && (
        <RequestLoader onClick={scrollToTop}>
          <div className="d-flex flex-column align-items-center">
            <span>⬆</span>
            <span>Top</span>
          </div>
        </RequestLoader>
      )}
    </>
  );
};

const ripple = keyframes`
  from {
    opacity: 1;
    transform: scale3d(0.75,0.75,1);
  }
  
  to {
    opacity: 0;
    transform: scale3d(1.5,1.5,1);
  }
`;

const rings = ({ duration, delay }) => css`
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: -8px;
  bottom: -8px;
  content: '';
  height: 100%;
  width: 100%;
  border: 4px solid rgba(190, 194, 190, 0.5);
  border-radius: 100%;
  animation-name: ${ripple};
  animation-duration: ${duration};
  animation-delay: ${delay};
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  z-index: -1;
`;

const RequestLoader = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  background: ${({ theme: { colors } }) => colors.Purple};
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  z-index: 10;

  span {
    font-size: 11px;
    text-align: center;
    color: ${({ theme: { colors } }) => colors.White};
  }

  &::after {
    ${rings({ duration: '3s', delay: '0s' })};
  }

  &::before {
    ${rings({ duration: '3s', delay: '0.5s' })};
  }
`;

export default ScrollTopButton;
