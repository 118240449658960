import { useContext, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { parse } from "papaparse";

import uploadIcon from "../../assets/images/upload.svg";
import TRData from "../../components/TRData";
import PapaDropFiles from "../../components/PapaDropFiles";
import { createAPIEndpoint, ENDPOINTS } from "../../api";
import { ThemeCtx } from "../../context";
import AuthContext from "../../context/AuthProvider";
import { HomeCtr } from "./ParseStyles";

const ParseTr = () => {
  const [selected, setSelected] = useState(false);
  const [pillars, setPillars] = useState([]);
  const [fileName, setFileName] = useState("");
  const [posted, setPosted] = useState(false);

  const { closed, theme } = useContext(ThemeCtx);
  const [region, setRegion] = useState();
  const { auth } = useContext(AuthContext);

  const ParseFullData = (trdata) => {
    console.log("TRDATA in ParseTr", trdata);
    // Year	Country_code	Country_Label	Infrastructure	Electronic_transactions	Payment_systems	Intellectual_property_rights	Other	stri_score
    let parsed = [];
    let cc = "";
    let pd = "";
    let pc = "";
    let yr = 0;
    trdata.forEach((tr) => {
      yr = tr.Year || 0;
      cc = tr.Country_code?.toString();
      delete tr.Year;
      delete tr.Country_code;
      delete tr.Country_Label;
      for (const key in tr) {
        if (key === "stri_score") {
          pc = "DG.0";
          pd = "STRI Score";
        } else if (key === "Infrastructure") {
          pc = "DG.1";
          pd = "Infrastructure and connectivity";
        } else if (key === "Electronic_transactions") {
          pc = "DG.2";
          pd = "Electronic transactions";
        } else if (key === "Payment_systems") {
          pc = "DG.3";
          pd = "Payment systems";
        } else if (key === "Intellectual_property_rights") {
          pc = "DG.4";
          pd = "Intellectual property rights";
        } else if (key === "Other") {
          pc = "DG.5";
          pd = "Other barriers affecting trade in digitally enabled services";
        }
        if (yr !== 0)
          parsed.push({
            region: region,
            year: yr,
            isoa3: cc,
            pillarCode: pc,
            pillarDescrip: pd,
            score: tr[key] || 0,
          });
      }
    });
    return parsed;
  };

  const postData = async () => {
    try {
      if (region === "-1") {
        setPosted(false);
        return false;
      }

      const toPost = pillars.map(({ id, ...pillars }) => pillars);
      const { data } = await createAPIEndpoint(
        ENDPOINTS.RESTRICTION,
        auth.accessToken
      ).post(toPost);
      if (data) {
        setPosted(true);
      }
    } catch (error) {
      console.log("Unsuccessful posting of pillars data", error);
    }
  };

  const handleChange = (file) => {
    parse(file, {
      header: true,
      dynamicTyping: true,
      complete: ({ data }) => {
        const parsed = ParseFullData(data);
        setPillars(parsed);
        setPosted(false);
        setFileName(`${file.name}- (${Math.floor(file.size / 1000)} KB)`);
      },
    });
  };

  const handleRegionChange = (e) => {    
    const newRegion = e.target.value;
    const updatedPillars = pillars.map(pillar => ({ ...pillar, region: newRegion }));
    setPillars(updatedPillars); // Update pillars with the new region
    setRegion(newRegion); // Update the region state
  };

  return (
    <HomeCtr closed={closed}>
      <h1
        className={`my-2 text-center fs-2 ${
          theme === "dark" ? "text-white" : ""
        } `}
      >
        Drop Trade Restriction data file here on CSV format
      </h1>
      <PapaDropFiles
        selected={selected}
        setSelected={setSelected}
        handleChange={handleChange}
      />
      {/* <div className="d-flex justify-content-between"> */}
      <Row>
        <Col>
          <Form.Select
            aria-label="Select the region..."
            value={region}
            onChange={handleRegionChange}
          >
            <option value="-1">Select the region...</option>
            <option value="eca">ECA</option>
            <option value="escap">ESCAP</option>
            <option value="eclac">ECLAC</option>
          </Form.Select>
        </Col>
        <Col>
          {!posted && (
            <h6 className="text-danger d-flex align-items-center fst-italic small ">
              You cannot click the upload button without selecting the
              corresponding region.
            </h6>
          )}
        </Col>
        <Col>
          <Button
            disabled={!pillars.length || posted || !region}
            className={`d-flex align-items-center gap-3 ${
              pillars.length ? "bg-success" : "bg-secondary"
            }`}
            onClick={postData}
          >
            <img
              src={uploadIcon}
              alt="Upload icon"
              width="25"
              height="25"
              style={{ cursor: "pointer" }}
            />
            Upload data
          </Button>
          {posted && <h3 className="text-success">Posted successfully</h3>}
        </Col>
      </Row>
      {/* </div> */}
      <TRData pillars={pillars} filename={fileName} />
    </HomeCtr>
  );
};

export default ParseTr;
