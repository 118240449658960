import { useRef, useState, useEffect } from "react";
import { FormInputCtr } from "./LoginFormStyles";
import { LoginCtr } from "./LoginFormStyles";
import { createAPIEndpoint, ENDPOINTS } from "../../api";
import jwt_decode from "jwt-decode";
import "../../index.css";
import Sidebar from "../Sidebar";
import useAuth from "../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";

const LoginForm = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await createAPIEndpoint(ENDPOINTS.LOGIN).login(
        user,
        pwd
      );
      // console.log(JSON.stringify(response?.data));
      // const data = response?.data;
      // if (data) await toggleLoggedIn(data);
      // const accessToken = response?.data?.accessToken;
      const accessToken = response?.data;
      var decodedToken = jwt_decode(accessToken);
      console.log(decodedToken);
      const roles = [
        decodedToken[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ],
      ];
      const email =
        decodedToken[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
        ];
      const givenname =
        decodedToken[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"
        ];
      const surname =
        decodedToken[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname"
        ];

      console.log(roles);
      console.log(email);
      console.log(givenname);
      console.log(surname);

      setAuth({ user, pwd, roles, accessToken });
      setUser("");
      setPwd("");
      navigate(from, { replace: true });
    } catch (err) {
      console.log(err);
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      // errRef.current.focus();
    }
  };

  return (
    <LoginCtr>
      <Sidebar />
      <form onSubmit={handleSubmit} autoComplete="on">
        <h2>Login</h2>
        <FormInputCtr>
          <label htmlFor="username">Username:</label>
          <input
            name="username"
            type="text"
            // placeholder="User Name"
            // label="User Name"
            autoComplete="off"
            ref={userRef}
            onChange={(e) => setUser(e.target.value)}
            value={user}
            required
          />
        </FormInputCtr>
        <FormInputCtr>
          <label htmlFor="password">Password:</label>
          <input
            id="password"
            name="password"
            type="password"
            placeholder="Password"
            label="Password"
            autoComplete="off"
            // onBlur={handleChange}
            onChange={(e) => setPwd(e.target.value)}
            value={pwd}
            required
          />
        </FormInputCtr>
        <button>Login</button>
        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        {/* {!loggedIn && values.username !== '' && values.password !== '' && (
          <ChangePwdMsg changed={loggedIn}>Failed to login!</ChangePwdMsg>
        )} */}
      </form>
    </LoginCtr>
  );
};

export default LoginForm;
