import axios from "axios";
export const BASE_URL = process.env.REACT_APP_API_URL;

export const ENDPOINTS = {
  COUNTRY: "country",
  COUNTRYPROFILE: "countryprofile",
  RESTRICTION: "restriction",
  INTEGRATION: "integration",
  PUBLICATION: "publication",
  PILLARS: "pillar",
  PILLARTI: "pillarti",
  AUTH: "auth",
  LOGIN: "login",
  CHANGEPWD: "changepwd",
  RESETPWD: "resetpwd",
};

export const createAPIEndpoint = (endpoint, token) => {
  let url = `${BASE_URL}/api/${endpoint}`;

  const options = {
    headers: {
      "Content-Type":
        endpoint == "COUNTRYPROFILE"
          ? "multipart/form-data"
          : "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json, text/plain, multipart/form-data",
    },
  };
  // console.log(options);

  return {
    changepwd: (pwd) => axios.post(url, pwd, options),
    resetpwd: (pwd) => axios.post(url, pwd, options),
    auth: (username, password) => {
      return axios.get(
        url + `?username=${username}&password=${password}`,
        options
      );
    },
    login: (username, password) => {
      return axios.post(url, JSON.stringify({ username, password }), {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
    },
    fetch: () => axios.get(url, options),
    fetchById: (id) => axios.get(url + id),
    post: (newRecord) => axios.post(url, newRecord, options),
    put: (id, updatedRecord) =>
      axios.put(url + `?id=${id}`, updatedRecord, options),
    delete: (id) => axios.delete(url + id, options),
  };
};
