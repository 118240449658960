import { useContext } from "react";
import { DtriContext } from "../context/dtri";

import {
  AccordionContext,
  useAccordionButton,
  Accordion,
  Card,
  Button,
  Table,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { HiChevronDoubleDown, HiChevronDoubleRight } from "react-icons/hi";
import styled from "styled-components";

function ContextAwareToggle({
  children,
  eventKey,
  callback,
  color,
  description,
}) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Button
      variant="light"
      className={`d-flex w-100 gap-2 justify-content-between align-items-center text-start text-decoration-none text-${color}`}
      onClick={decoratedOnClick}
    >
      <div className="d-flex gap-2 align-items-center">
        {isCurrentEventKey ? (
          <HiChevronDoubleDown size={`${1}em`} />
        ) : (
          <HiChevronDoubleRight size={`${1}em`} />
        )}
        <span className="small">
          <strong>{description}</strong>
        </span>
      </div>
      {children}
    </Button>
  );
}

const AccordionPillars = ({ pillars, color }) => {
  const { t } = useTranslation(["common", "eca", "escap", "eclac"]);
  const { region } = useContext(DtriContext);
  const langPillars = t("TIS.Pillars", { ns: region, returnObjects: true });
  let scores = {};
  pillars.forEach((pill) => (scores = { ...scores, [pill.code]: pill.score }));

  return (
    <Accordion defaultActiveKey="0">
      {langPillars.map((pillar, idx) => {
        const { code, description, body } = pillar;
        return (
          code !== "00" && (
            <Card key={code}>
              {!code.includes(".") && (
                <Card.Header className="p-0">
                  <ContextAwareToggle
                    eventKey={idx.toString()}
                    color={color}
                    className="p-0"
                    description={description}
                  >
                    <HeaderScore>{scores[code].toFixed(2)}</HeaderScore>
                  </ContextAwareToggle>
                </Card.Header>
              )}
              <Accordion.Collapse eventKey={idx.toString()}>
                <Card.Body className="p-0 m-0">
                  <Table striped bordered hover size="sm" variant={color}>
                    <tbody>
                      {body.map((rw, index) =>
                        rw.code.substring(0, 2) === "0." ? (
                          <tr key={rw.code}>
                            <TD className="p-2 fw-bold" colSpan={3}>
                              {rw.description}
                            </TD>
                          </tr>
                        ) : (
                          <tr key={rw.code}>
                            <TD>{rw.code}</TD>
                            <TD>{rw.description}</TD>
                            <TD>{scores[rw.code]}</TD>
                            {/* <TD>{scores[rw.code].toFixed(2)}</TD> */}
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          )
        );
      })}
    </Accordion>
  );
};

const TD = styled.td`
  font-size: 12px;
`;

const HeaderScore = styled.span`
  font-size: 14px;
  color: ${({ theme: { colors } }) => colors.Red};
  font-weight: bold;
`;

export default AccordionPillars;
