import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { ThemeCtx } from "../context";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "../GlobalStyle";
import Sidebar from "../components/Sidebar";

const AdminLayout = () => {
  const { theme } = useContext(ThemeCtx);

  return (
    <ThemeProvider theme={{ theme }}>
      <GlobalStyle theme={theme} />
      <Sidebar />
      <main className="App">
        <Outlet />
      </main>
    </ThemeProvider>
  );
};

export default AdminLayout;
