import { useContext, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { DtriContext } from "../context/dtri";
import Header from "./Header";
import LandingHeader from "./LandingHeader";
import Theme from "../Theme";
import Footer from "./Footer";
import ScrollTopButton from "../components/common/ScrollTopButton";

const Layout = () => {
  const { handleRegion } = useContext(DtriContext);
  let { region } = useParams();
  useEffect(() => {
    region = (region === null) | (region === undefined) ? "common" : region;
    handleRegion(region);
  }, [region]);

  return (
    <Theme>
      <main className="App">
        <ScrollTopButton />
        {region === "common" ? <LandingHeader /> : <Header />}
        <Outlet />
        <Footer />
      </main>
    </Theme>
  );
};

export default Layout;
