import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../components/common";
import { Container, Card, Row, Col } from "react-bootstrap";
import { DtriContext } from "../../context/dtri";
import { MdDownload } from "react-icons/md";
import styled from "styled-components";

const CountryProfile = () => {
  const { countries } = useContext(DtriContext);
  const { t, i18n } = useTranslation(["common", "eca", "escap", "eclac"]);
  const { region } = useContext(DtriContext);
  const countries_names = t("CountriesA3", { ns: region, returnObjects: true });

  // filter the returned countries by region and active flag
  const region_countries = countries.filter(
    (country) => (country.region === region) & (country.active === true)
  );

  let colsPerRow = 5;
  let flags = [];
  let temp = [];

  // Fill the country name in the correct language
  region_countries.forEach((item, idx) => {
    item["countryName"] = countries_names[item.isoa3];
  });

  // Sort the countries based on the country name in the correct language
  const sortedCountries = region_countries.sort((a, b) =>
    a.countryName < b.countryName ? -1 : a.countryName > b.countryName ? 1 : 0
  );

  sortedCountries.forEach((item, idx) => {
    if (idx !== 0 && idx % colsPerRow === 0) {
      flags.push(temp);
      temp = [
        {
          code: item.isoa3.toLowerCase(),
          name: countries_names[item.isoa3],
          validated: item.profileValidated,
        },
      ];
    } else {
      temp.push({
        code: item.isoa3.toLowerCase(),
        name: countries_names[item.isoa3],
        validated: item.profileValidated,
      });
    }
  });
  if (temp.length) flags.push(temp);

  return (
    <Container>
      <PageTitle title={t("CP.title", { ns: region })}>
        <p>{t("CP.intro", { ns: region })}</p>
        {flags.map((cntry, idx) => {
          colsPerRow = cntry.length < colsPerRow ? cntry.length : colsPerRow;

          return (
            <Row xs={1} md={colsPerRow} className="g-4 m-2" key={idx}>
              {cntry.map((c) => {
                return (
                  <Col key={c.code}>
                    <CardCtr>
                      <Card.Img
                        variant="top"
                        src={require(`../../assets/pngFlags/${c.code}.svg`)}
                      />
                      <Card.Body>
                        <Card.Text>{c.name}</Card.Text>
                      </Card.Body>
                      <Card.Footer>
                        <CardFtr>
                          {c.validated ? (
                            <a
                              className="fst-italic"
                              target={"_blank"}
                              // href={
                              //   process.env.PUBLIC_URL +
                              //   `assets/data/country-profile/${c.code}-country-profile-${i18n.language}.pdf`
                              // }
                              href={
                                process.env.REACT_APP_API_URL +
                                `/uploads/country-profile/${c.code}-country-profile-${i18n.language}.pdf`
                              }
                              download={`${c.code}-country-profile-${i18n.language}.pdf`}
                            >
                              <MdDownload />
                              {t("CP.download_msg", {
                                countryName: c.name,
                                ns: region,
                              })}
                            </a>
                          ) : (
                            <span>
                              {t("CP.coming_soon_msg", { ns: region })}
                            </span>
                          )}
                        </CardFtr>
                      </Card.Footer>
                    </CardCtr>
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </PageTitle>
    </Container>
  );
};

const CardCtr = styled(Card)`
  display: flex;
  justify-content: center;
  border-radius: 10px;
  transform: scale(1);
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-box-shadow: ${({ theme: { shadows } }) => shadows.BoxShadow2};
  box-shadow: ${({ theme: { shadows } }) => shadows.BoxShadow2};

  &:hover {
    transform: scale(1.05);
    -webkit-box-shadow: ${({ theme: { shadows } }) => shadows.BoxShadow};
    box-shadow: ${({ theme: { shadows } }) => shadows.BoxShadow};
  }

  > img {
    min-height: 10.5rem !important;
    max-height: 12rem !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
`;

const CardFtr = styled.small`
  font-size: 0.7rem;
`;

export default CountryProfile;
