import {
  FaGlobeAfrica,
  FaGlobeAsia,
  FaGlobeAmericas,
  FaHome,
  FaRegHandshake,
  FaTrafficLight,
  FaRegFilePdf,
  FaInfoCircle,
} from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { RiGlobeFill } from "react-icons/ri";
import { IoArrowBack } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { DtriContext } from "../context/dtri";

const iconColor = "white";
const iconSize = 18;

export function useNavbar() {
  const { t } = useTranslation(["common", "eca", "escap", "eclac"]);
  const navItems = t("Navbar", { returnObjects: true });
  const items = Array.from(Object.values(navItems));
  const { region } = useContext(DtriContext);

  return {
    icons: [
      {
        key: 0,
        path: "/home",
        icon: <FaHome color={iconColor} size={iconSize} />,
      },
      {
        key: 1,
        path: `/${region}/home`,
        icon: <FaInfoCircle color={iconColor} size={iconSize} />,
      },
      {
        key: 2,
        path: `/${region}/trade-integration`,
        icon: <FaGlobeAfrica color={iconColor} size={iconSize} />,
      },
      {
        key: 3,
        path: `/${region}/trade-restriction`,
        icon: <FaTrafficLight color={iconColor} size={iconSize} />,
      },
      {
        key: 4,
        path: `/${region}/country-profile`,
        icon: <RiGlobeFill color={iconColor} size={iconSize} />,
      },
      {
        key: 5,
        path: `/${region}/publication`,
        icon: <FaRegFilePdf color={iconColor} size={iconSize} />,
      },
      {
        key: 6,
        path: "/home",
        icon: <IoArrowBack color={iconColor} size={iconSize} />,
      },
    ],
    items,
  };
}

export function useLandingNavbar() {
  const { t } = useTranslation(["common", "eca", "escap", "eclac"]);
  const navItems = t("Navbar", { returnObjects: true });

  const items = Array.from(Object.values(navItems));
  return {
    landing_icons: [
      {
        key: 0,
        path: "/home",
        icon: <FaHome color={iconColor} size={iconSize} />,
      },
      {
        key: 1,
        path: "/eca/home",
        icon: <FaGlobeAfrica color={iconColor} size={iconSize} />,
      },
      {
        key: 2,
        path: "/escap/home",
        icon: <FaGlobeAsia color={iconColor} size={iconSize} />,
      },
      {
        key: 3,
        path: "/eclac/home",
        icon: <FaGlobeAmericas color={iconColor} size={iconSize} />,
      },
      {
        key: 4,
        path: "/partners-initiative",
        icon: <FaRegHandshake color={iconColor} size={iconSize} />,
      },
      {
        key: 5,
        path: "/contact-us",
        icon: <FiPhoneCall color={iconColor} size={iconSize} />,
      },
    ],
    items,
  };
}
