import { useContext } from "react";
import {
  SidebarCtr,
  Image,
  ImageText,
  Header,
  Menu,
  NavLink,
  Menubar,
  LogoText,
} from "./SidebarStyles";
import { BiChevronRight, BiLogOut } from "react-icons/bi";
import { sidebarItems } from "../../constants";
import { ThemeCtx } from "../../context/";
import AuthContext from "../../context/AuthProvider";
import { Button } from "react-bootstrap";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const { closed, toggleClosed, theme } = useContext(ThemeCtx);
  const { setAuth } = useContext(AuthContext);
  const { auth } = useAuth();
  const navigate = useNavigate();

  const logout = async () => {
    // if used in more components, this should be in context axios to /logout endpoint
    setAuth({});
    navigate("/admin/login");
  };

  return (
    <SidebarCtr closed={closed}>
      <Header theme={theme} closed={closed}>
        <ImageText>
          <Image closed={closed}>
            <img src="logo.png" alt="logo" />
          </Image>
          <LogoText closed={closed}>
            <span className="name">DTRI Admin</span>
            <span className="profession">
              United Nations Regional Commisions
            </span>
          </LogoText>
        </ImageText>

        <BiChevronRight
          className="toggle"
          size={25}
          onClick={() => toggleClosed(!closed)}
        />
      </Header>
      <Menubar>
        <Menu>
          {auth?.user && (
            <ul>
              {sidebarItems.map((sbi) => (
                <li key={sbi.id}>
                  <NavLink to={sbi.path} theme={theme}>
                    {sbi.icon}
                    <span className="text">{sbi.title}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          )}
        </Menu>
        <div>
          {auth?.user && (
            <li>
              <NavLink to="/admin/home" theme={theme}>
                <BiLogOut className="icon-style" />
                <Button variant="link" onClick={logout}>
                  Logout
                </Button>
              </NavLink>
            </li>
          )}
        </div>
      </Menubar>
    </SidebarCtr>
  );
};

export default Sidebar;
