import React, { useContext } from "react";
import { Table } from "react-bootstrap";
import { ThemeCtx } from "../context";

const PubData = ({ publications, filename }) => {
  const { theme } = useContext(ThemeCtx);
  return (
    <div className="py-3">
      <h1 className={`${theme === "dark" ? "text-white" : ""}`}>{filename}</h1>
      <Table responsive="sm" striped bordered hover variant={theme}>
        <thead>
          <tr>
            <th>Publication Name En</th>
            <th>Publication Name Fr</th>
            <th>Publication Name Sp</th>
            <th>Publication Description En</th>
            <th>Publication Description Fr</th>
            <th>Publication Description Sp</th>
            <th>File Name En</th>
            <th>File Name Fr</th>
            <th>File Name Sp</th>
          </tr>
        </thead>
        <tbody>
          {publications.map((pub, index) => (
            <tr key={`${pub.pubNameEn}-${index}`}>
              <td>{pub.pubNameEn}</td>
              <td>{pub.pubNameFr}</td>
              <td>{pub.pubNameSp}</td>
              <td>{pub.pubDescriptionEn}</td>
              <td>{pub.pubDescriptionFr}</td>
              <td>{pub.pubDescriptionSp}</td>
              <td>{pub.pubFileNameEn}</td>
              <td>{pub.pubFileNameFr}</td>
              <td>{pub.pubFileNameSp}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default PubData;
