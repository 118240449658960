import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

export const CountryNameTitle = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 5%;
  }
`;

export const CountryNameLink = styled.div`
  img {
    flex: 2;
    margin-right: 5px;
    width: 15%;
    border: 1px solid black;
  }
`;

export const PillerLink = styled(Button)`
  text-align: left;
  color: ${({ theme: { colors } }) => colors.Grey};
`;

export const Img = styled.img`
  width: 20%;
`;
