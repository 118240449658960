import { useState, useEffect } from 'react';

function getWindowDim() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
    tarWidth: width <= 540 ? '100vw' : '50vw',
    tarHeigh: height <= 720 ? 'auto' : '100vh',
  };
}

export default function useWindowDim() {
  const [winDim, setWinDim] = useState(getWindowDim());

  useEffect(() => {
    const handleResize = () => setWinDim(getWindowDim());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [winDim]);

  return winDim;
}
