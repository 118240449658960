import { ThemeProvider } from 'styled-components';

const theme = {
  colors: {
    Grey: '#919191',
    LightGrey: '#fcfafa',
    Green: '#5ca049',
    LightGreen: '#63d471',
    Purple: '#904c85',
    Blue: '#0b629f',
    Teal: '#1692a1',
    DarkTeal: '#147c88',
    Red: '#a50a17',
    White: '#fff',
    Orange: '#ebb654',
    Black: '#5e5b5b',
    YellowGreen: '#c0ff11',
  },
  fonts: ['Sora', 'sans-serif', 'Roboto', 'Courier'],
  fontSizes: {
    small: '1em',
    medium: '2em',
    large: '3em',
  },
  shadows: {
    BoxShadow: '0px 0px 15px -10px rgba(0, 0, 0, 0.9)',
    BoxShadow2: '0px 0px 15px -10px rgba(0, 0, 0, 0.5)',
  },
};

const Theme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
