import { IoOptionsOutline, IoHome } from "react-icons/io5";
import {
  AiOutlineTrademark,
  AiFillLock,
  AiOutlineProfile,
} from "react-icons/ai";
import { RxGlobe } from "react-icons/rx";
import { FaRegFilePdf } from "react-icons/fa";

const iconSize = 30;

export const sidebarItems = [
  {
    id: 0,
    icon: <IoHome size={iconSize} className="icon-style" />,
    path: "/admin",
    title: "Home",
  },
  {
    id: 1,
    icon: <IoOptionsOutline size={iconSize} className="icon-style" />,
    path: "/admin/parseti",
    title: "Upload Trade Integration",
  },
  {
    id: 2,
    icon: <AiOutlineTrademark size={iconSize} className="icon-style" />,
    path: "/admin/parsetr",
    title: "Upload Trade Restriction",
  },
  {
    id: 3,
    icon: <FaRegFilePdf size={iconSize} className="icon-style" />,
    path: "/admin/parsepub",
    title: "Additional Resources",
  },
  {
    id: 4,
    icon: <RxGlobe size={iconSize} className="icon-style" />,
    path: "/admin/country",
    title: "Country Management",
  },
  {
    id: 5,
    icon: <AiOutlineProfile size={iconSize} className="icon-style" />,
    path: "/admin/countryprofile",
    title: "Country Profile Management",
  },
  {
    id: 6,
    icon: <AiFillLock size={iconSize} className="icon-style" />,
    path: "/admin/changepwd",
    title: "Change User Password",
  },
  {
    id: 7,
    icon: <AiFillLock size={iconSize} className="icon-style" />,
    path: "/admin/resetpwd",
    title: "Reset Password (SuperAdmin)",
  },
];
