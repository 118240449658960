import { useState } from 'react';
import { FormInputCtr } from './LoginFormStyles';

const FormInput = (props) => {
  const [focused, setFocused] = useState(false);
  const { label, onChange, errorMessage, ...otherProps } = props;

  return (
    <FormInputCtr>
      <label>{label}</label>
      <input
        {...otherProps}
        autoComplete="off"
        onChange={onChange}
        onBlur={() => setFocused(true)}
        onFocus={() => otherProps.name === 'confirmPwd' && setFocused(true)}
        focused={focused.toString()}
      />
      <span>{errorMessage}</span>
    </FormInputCtr>
  );
};

export default FormInput;
