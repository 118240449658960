import { useContext, useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Dropzone from "react-dropzone";
import Form from "react-bootstrap/Form";

import uploadIcon from "../../assets/images/upload.svg";
import { createAPIEndpoint, ENDPOINTS } from "../../api";
import { ThemeCtx } from "../../context";
import AuthContext from "../../context/AuthProvider";
import { HomeCtr } from "./ParseStyles";
import "bootstrap/dist/css/bootstrap.min.css";

const CountryProfileUpload = () => {
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [currentFile, setCurrentFile] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [fileName, setFileName] = useState("");
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const { closed, theme } = useContext(ThemeCtx);
  const { auth } = useContext(AuthContext);
  const [country, setCountry] = useState();
  const [countries, setCountries] = useState([]);
  const [language, setLanguage] = useState();

  useEffect(() => {
    async function fetchCountries() {
      const { data } = await createAPIEndpoint(ENDPOINTS.COUNTRY).fetch();
      setCountries(data);
    }
    fetchCountries();
  }, []);

  const createFile = async (bits, name, options) => {
    try {
      // If this fails, we go for Blob
      return new File(bits, name, options);
    } catch (e) {
      // If we're here a new File could not be constructed
      var myBlob = new Blob(bits, options || {});
      myBlob.lastModified = new Date();
      myBlob.name = name;
      return myBlob;
    }
  };

  const uploadFile = async () => {
    try {
      if (country === "-1" || language === "-1") {
        setIsFileSelected(false);
        return false;
      }

      let selectedFileName = `${country.toLowerCase()}-country-profile-${language}.pdf`;
      setFileName(selectedFileName);
      console.log(selectedFileName);

      let currentFile = selectedFiles[0];
      setProgress(30);
      setCurrentFile(currentFile);

      if (currentFile.name != selectedFileName) {
        setProgress(0);
        setMessage(
          "Please make sure to rename the file according to the naming convention: " +
            selectedFileName
        );
        setCurrentFile(undefined);
        setSelectedFiles(undefined);
        setIsFileSelected(false);
        setIsFileUploaded(false);
        return false;
      }

      // const countryProfileFile = await createFile(
      //   [currentFile],
      //   selectedFileName,
      //   {
      //     type: currentFile.type,
      //   }
      // );

      const formData = new FormData();
      formData.append("File", currentFile);
      const { data } = await createAPIEndpoint(
        ENDPOINTS.COUNTRYPROFILE,
        auth.accessToken
      ).post(formData);
      setProgress(60);

      if (data) {
        setIsFileUploaded(true);
        setMessage("Country profile has been sucessfully uploaded!");
      }
      setIsFileUploaded(true);
    } catch (error) {
      console.log("Unsuccessful upload of country profile!", error);
      setProgress(0);
      setMessage("Unsuccessful upload of country profile!", error);
      setCurrentFile(undefined);
    }
    setSelectedFiles(undefined);
    setIsFileSelected(false);
    setProgress(100);
  };

  const onDrop = (files) => {
    if (files.length > 0) {
      setSelectedFiles(files);
      setIsFileSelected(true);
    }
  };

  const fileFormats = {
    "file/pdf": [".pdf", ".PDF"],
  };

  return (
    <HomeCtr closed={closed}>
      <h1
        className={`my-2 text-center fs-2 ${
          theme === "dark" ? "text-white" : ""
        } `}
      >
        Drop country profile file here on PDF format
      </h1>
      <Row>
        <Col>
          <Dropzone onDrop={onDrop} multiple={false} accept={fileFormats}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  {selectedFiles && selectedFiles[0].name ? (
                    <div className="selected-file">
                      {selectedFiles && selectedFiles[0].name}
                    </div>
                  ) : (
                    "Drag and drop file here, or click to select file"
                  )}
                </div>
                <aside className="selected-file-wrapper">
                  <Button
                    className="btn btn-success gap-3"
                    disabled={!selectedFiles || !country || !language}
                    onClick={uploadFile}
                  >
                    <img
                      src={uploadIcon}
                      alt="Upload icon"
                      width="23"
                      height="23"
                      style={{ cursor: "pointer", paddingRight: "0.3em" }}
                    />
                    Upload
                  </Button>
                </aside>
              </section>
            )}
          </Dropzone>
        </Col>
      </Row>
      <Row style={{ marginTop: "1em" }}>
        <Col>
          <Form.Select
            aria-label="Select the country..."
            value={country}
            data={countries}
            onChange={(e) => {
              console.log("e.target.value", e.target.value);
              setCountry(e.target.value);
            }}
          >
            <option value="">Select the country...</option>
            {countries.map((item) => {
              return (
                <option key={item.isoa3} value={item.isoa3}>
                  {item.countryName}
                </option>
              );
            })}
          </Form.Select>
        </Col>
        <Col>
          <Form.Select
            aria-label="Select the language..."
            value={language}
            onChange={(e) => {
              console.log("e.target.value", e.target.value);
              setLanguage(e.target.value);
            }}
          >
            <option value="">Select the language...</option>
            <option value="en">English</option>
            <option value="fr">French</option>
            <option value="es">Spanish</option>
          </Form.Select>
        </Col>
        <Col>
          {(!isFileSelected || !country || !language) && (
            <h6 className="text-danger d-flex align-items-center fst-italic small ">
              You cannot click the upload button without selecting the
              corresponding PDF, country, and language.
            </h6>
          )}
        </Col>
        {/* <Col>
          <Button
            disabled={!isFileSelected || !country || !language}
            className={`d-flex align-items-center gap-3 ${
              isFileSelected ? "bg-success" : "bg-secondary"
            }`}
            onClick={uploadFile}
          >
            <img
              src={uploadIcon}
              alt="Upload icon"
              width="25"
              height="25"
              style={{ cursor: "pointer" }}
            />
            Upload Profile
          </Button>
          {isFileUploaded && (
            <h3 className="text-success">{`${country} profile in ${language} has been successfully uploaded!`}</h3>
          )}
        </Col> */}
      </Row>
      <Row>
        <Col> &nbsp; </Col>
      </Row>
      {/* <Row>
        <Col>
          <input
            type="file"
            name="file"
            accept=".pdf"
            onChange={handleChange}
          />
          {selectedFiles ? (
            <div>
              <p>Filename: {selectedFiles[0].name}</p>
              <p>Filetype: {selectedFiles[0].type}</p>
              <p>Size in bytes: {selectedFiles[0].size}</p>
              <p>
                lastModifiedDate:{" "}
                {selectedFiles[0].lastModifiedDate.toLocaleDateString()}
              </p>
            </div>
          ) : (
            <p>Select a file to show details</p>
          )}
        </Col>
      </Row> */}
      <Row>
        <Col>
          {currentFile && (
            <div className="progress mb-3">
              <div
                className="progress-bar progress-bar-info progress-bar-striped"
                role="progressbar"
                aria-valuenow={progress}
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: progress + "%" }}
              >
                {progress}%
              </div>
            </div>
          )}
          <div
            className={
              isFileUploaded ? "alert alert-success" : "alert alert-danger"
            }
            role="alert"
            style={{ marginTop: "1rem" }}
          >
            {message}
          </div>
        </Col>
      </Row>
    </HomeCtr>
  );
};

export default CountryProfileUpload;
