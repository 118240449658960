import { CountryListCountry, CountryListImg } from './CountryStyles';

export const columns = [
  { field: 'id', headerName: 'ID', width: 70, hide: true },
  { field: 'isoa3', headerName: 'Isoa3', width: 130 },
  {
    field: 'countryName',
    headerName: 'Country',
    width: 340,
    renderCell: (params) => {
      return (
        <CountryListCountry>
          <CountryListImg
            src={`/assets/pngFlags/${params.row.isoa3}.png`}
            alt={params.row.countryName}
          />
          {params.row.countryName}
        </CountryListCountry>
      );
    },
  },
  {
    field: 'restrictionValidated',
    headerName: 'Restriction Validated',
    type: 'boolean',
    sortable: false,
    editable: true,
    width: 200,
  },
  {
    field: 'profileValidated',
    headerName: 'Profile Validated',
    type: 'boolean',
    sortable: false,
    editable: true,
    width: 200,
  },
  {
    field: 'active',
    headerName: 'Active',
    type: 'boolean',
    sortable: false,
    editable: true,
    width: 160,
  },
];
