import React, { useContext } from "react";
import { Table } from "react-bootstrap";
import { ThemeCtx } from "../context";

const TIData = ({ pillars, filename }) => {
  const { theme } = useContext(ThemeCtx);
  const sorted = pillars.sort((a, b) =>
    a.isoa3 < b.isoa3 ? -1 : a.isoa3 > b.isoa3 ? 1 : 0
  );
  return (
    <div className="py-3">
      <h1 className={`${theme === "dark" ? "text-white" : ""}`}>{filename}</h1>
      <Table responsive="sm" striped bordered hover variant={theme}>
        <thead>
          <tr>
            <th>Code</th>
            <th>Description</th>
            <th>Isoa3</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          {sorted.map((pillar) => (
            <tr key={`${pillar.code}-${pillar.isoa3}`}>
              <td>{pillar.code}</td>
              <td>{pillar.description}</td>
              <td>{pillar.isoa3}</td>
              <td>{pillar.score}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default TIData;
