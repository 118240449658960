import { Routes, Route } from "react-router-dom";
import RequireAuth from "./components/RequireAuth";
import styled from "styled-components";

import { DtriProvider } from "./context/dtri";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import AdminLayout from "./components/AdminLayout";
import LandingPage from "./pages/LandingPage";
import TradeRestriction from "./pages/TradeRestriction";
import TradeIntegration from "./pages/TradeIntegration";
import CountryProfile from "./pages/CountryProfile";
import Publication from "./pages/Publication";
import PartnersInitiative from "./pages/PartnersInitiative";
import ContactUs from "./pages/ContactUs";
import Missing from "./components/Missing";
import ParseTi from "./pages/Admin/ParseTi";
import Login from "./components/Auth/Login";
import ChangePassword from "./components/Auth/ChangePassword";
import CountryList from "./components/Country";
import ParsePub from "./pages/Admin/ParsePub";
import ParseTr from "./pages/Admin/ParseTr";
import CountryProfileUpload from "./pages/Admin/CountryProfileUpload";
import AdminHome from "./pages/Admin/AdminHome";
import ResetPassword from "./components/Auth/ResetPassword";
import Unauthorized from "./components/Unauthorized";

const ROLES = {
  User: "User",
  Admin: "Administrator",
  Super: "SuperAdmin",
};

function App() {
  return (
    <DtriProvider>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public pages accessible to all users */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/home" element={<LandingPage />} />
          <Route path="/:region/home" element={<Home />} />
          <Route
            path="/:region/trade-restriction"
            element={<TradeRestriction />}
          />
          <Route
            path="/:region/trade-integration"
            element={<TradeIntegration />}
          />
          <Route path="/:region/country-profile" element={<CountryProfile />} />
          <Route path="/:region/publication" element={<Publication />} />

          <Route path="/partners-initiative" element={<PartnersInitiative />} />
          <Route path="/contact-us" element={<ContactUs />} />

          {/* catch all */}
          <Route path="*" element={<Missing />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
        </Route>

        <Route path="/admin" element={<AdminLayout />}>
          {/* public pages accessible to all users */}
          <Route path="/admin/login" element={<Login />} />

          {/* we want to protect these routes for the admin area */}
          <Route
            element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Super]} />}
          >
            <Route path="/admin/home" element={<AdminHome />} />
            <Route path="/admin/parseti" element={<ParseTi />} />
            <Route path="/admin/parsetr" element={<ParseTr />} />
            <Route path="/admin/parsepub" element={<ParsePub />} />
            <Route path="/admin/country" element={<CountryList />} />
            <Route
              path="/admin/countryprofile"
              element={<CountryProfileUpload />}
            />
            <Route path="/admin/changepwd" element={<ChangePassword />} />
          </Route>
          {/* we want to protect these routes for the super admin area */}
          <Route element={<RequireAuth allowedRoles={[ROLES.Super]} />}>
            <Route path="/admin/resetpwd" element={<ResetPassword />} />
          </Route>
        </Route>
      </Routes>
    </DtriProvider>
  );
}

const Container = styled.div`
  width: 100%;
  font-family: ${(props) => props.theme.fonts[0]};
  background-color: ${({ theme: { colors } }) => colors.White};
`;

export default App;
