import { useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { DtriContext } from "../../context/dtri";

const GaugeChart = ({ value = 0 }) => {
  const { t } = useTranslation(["common", "eca", "escap", "eclac"]);
  const { region } = useContext(DtriContext);

  if (value < 0 || value > 1) return;
  return (
    <Gauge>
      <GaugeBody>
        <GaugeFill value={value}></GaugeFill>
        <GaugeCover>
          <h4>{value}</h4>
          <span>{t("TIS.overall_score", { ns: region })}</span>
        </GaugeCover>
      </GaugeBody>
    </Gauge>
  );
};

const Gauge = styled.div`
  margin: 10px;
  width: 100%;
  max-width: 250px;
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  color: #004033;
`;

const GaugeBody = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  background: #b4c0be;
  position: relative;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
  overflow: hidden;
`;

const GaugeFill = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: 100%;
  background: teal;
  transform-origin: center top;
  transform: ${({ value }) => `rotate(${value / 2}turn)`};
  transition: transform 0.2s ease-out;
`;

const GaugeCover = styled.div`
  width: 75%;
  height: 150%;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 25%;
  transform: translate(-50%);

  /* Text */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 33%;
  box-sizing: border-box;

  > span {
    font-size: 20px;
  }
`;

export default GaugeChart;
