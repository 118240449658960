import styled from 'styled-components';

export const CountryListCtr = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-left: ${({ closed }) => (closed ? '110px' : '330px')};
`;

export const CountryListCountry = styled.div`
  display: flex;
  align-items: center;
`;

export const CountryListImg = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
`;

export const CountryListEdit = styled.button`
  border: none;
  border-radius: 10px;
  padding: 0px 20px;
  background-color: #3bb077;
  color: white;
  cursor: pointer;
  /* margin-right: 20px; */
`;

export const CountryListDelete = styled.div`
  color: red;
  cursor: pointer;
`;
